@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-tap-highlight-color: transparent;
}
.space--between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.margin {
  margin: 10px;
}
.marginTop {
  margin-top: 10px;
}
.marginRight {
  margin-right: 10px;
}
.marginBottom {
  margin-bottom: 10px;
}
.marginLeft {
  margin-left: 10px;
}
.margin--xsmall {
  margin: 5px;
}
.marginTop--xsmall {
  margin-top: 5px;
}
.marginRight--xsmall {
  margin-right: 5px;
}
.marginBottom--xsmall {
  margin-bottom: 5px;
}
.marginLeft--xsmall {
  margin-left: 5px;
}
.margin--xxlarge {
  margin: 200px;
}
.marginTop--xxlarge {
  margin-top: 200px;
}
.marginRight--xxlarge {
  margin-right: 200px;
}
.marginBottom--xxlarge {
  margin-bottom: 200px;
}
.marginLeft--xxlarge {
  margin-left: 200px;
}
.margin--xlarge {
  margin: 100px;
}
.marginTop--xlarge {
  margin-top: 100px;
}
.marginRight--xlarge {
  margin-right: 100px;
}
.marginBottom--xlarge {
  margin-bottom: 100px;
}
.marginLeft--xlarge {
  margin-left: 100px;
}
.margin--large {
  margin: 40px;
}
.marginTop--large {
  margin-top: 40px;
}
.marginRight--large {
  margin-right: 40px;
}
.marginBottom--large {
  margin-bottom: 40px;
}
.marginLeft--large {
  margin-left: 40px;
}
.margin--small {
  margin: 20px;
}
.marginTop--small {
  margin-top: 20px;
}
.marginRight--small {
  margin-right: 20px;
}
.marginBottom--small {
  margin-bottom: 20px;
}
.marginLeft--small {
  margin-left: 20px;
}
@media (max-width: 1249px) {
  .marginTop--xxlarge {
    margin-top: 150px;
  }
  .marginBottom--xxlarge {
    margin-bottom: 150px;
  }
  .marginTop--xlarge {
    margin-top: 80px;
  }
  .marginBottom--xlarge {
    margin-bottom: 80px;
  }
}

@media (max-width: 767px) {
  .marginTop--large {
    margin-top: 30px;
  }
  .marginBottom--large {
    margin-bottom: 30px;
  }
  .marginTop--xlarge {
    margin-top: 60px;
  }
  .marginBottom--xlarge {
    margin-bottom: 60px;
  }
  .marginTop--xxlarge {
    margin-top: 60px;
  }
  .marginBottom--xxlarge {
    margin-bottom: 60px;
  }
}
@media (max-width: 480px) {
  .marginTop--xlarge {
    margin-top: 30px;
  }
  .marginBottom--xlarge {
    margin-bottom: 30px;
  }
}
.spinner_wrapper {
  position: relative;
}
.spinner_wrapper > div {
  width: 24px;
  height: 19px;
  border: 1px solid #5B5B5B;
  text-align: center;
  margin-bottom: 2px;
  font-size: 14px;
  cursor: pointer;
  position: relative;
}
.spinner_wrapper > div .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -8px;
  margin-left: -10px;
  width: 20px;
  height: 14px;
  line-height: 14px;
}
.spinner_wrapper > div:last-child {
  margin-bottom: 0;
}
.spinner_wrapper > div:hover {
  background-color: #008AC9;
  color: #ffffff;
  border-color: #008AC9;
}
.quantity {
  position: relative;
  width: 76px;
}
.quantity input {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  width: calc(100% - 25px);
  width: -moz-calc(100% - 25px);
  width: -webkit-calc(100% - 25px);
  width: -o-calc(100% - 25px);
}
.quantity .spinner_wrapper {
  position: absolute;
  right: 0;
  top: 0;
}
.quantity.disabled .spinner_wrapper > div {
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  color: #757579;
  cursor: default;
}
@media (max-width: 1249px) {
  .quantity {
    width: 50px;
  }
  .quantity input {
    width: 100%;
  }
  .quantity .spinner_wrapper {
    display: none;
  }
}
.orderbutton {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -20px;
  margin-right: -20px;
}
.orderbutton:before,
.orderbutton:after {
  display: none;
}
.orderbutton > div {
  padding-left: 20px;
  padding-right: 20px;
}
.orderbutton__left {
  width: 380px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.orderbutton__left:before,
.orderbutton__left:after {
  display: none;
}
@media (max-width: 1539px) {
  .orderbutton__left {
    width: 320px;
  }
}
@media (max-width: 480px) {
  .orderbutton__left {
    width: 100%;
  }
}
.orderbutton__right {
  padding-left: 20px;
  padding-right: 20px;
  text-align: right;
}
@media (max-width: 480px) {
  .orderbutton__right {
    width: 100%;
    margin-top: 10px;
  }
}
.orderbutton .button_wrapper {
  flex-grow: 1;
  padding-left: 2px;
}
.orderbutton .itemcard_order_submit_button {
  display: inline-block;
  vertical-align: middle;
  padding: 9px 20px 11px 20px;
  line-height: 18px;
  font-size: 18px;
  min-width: 200px;
  border: 1px solid #5B5B5B;
  color: #5B5B5B;
  cursor: pointer;
  background-color: #ffffff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 400;
  background-color: #008AC9;
  color: #ffffff;
  border-color: #008AC9;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 22px;
  line-height: 1;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  text-transform: none;
  min-height: 40px;
}
.orderbutton .itemcard_order_submit_button:hover,
.orderbutton .itemcard_order_submit_button:focus {
  background-color: #008AC9;
  border-color: #008AC9;
  color: #ffffff;
  text-decoration: none;
}
.orderbutton .itemcard_order_submit_button:active {
  background-color: #006796;
  border-color: #006796;
  color: #ffffff;
  text-decoration: none;
}
.orderbutton .itemcard_order_submit_button:hover,
.orderbutton .itemcard_order_submit_button:focus {
  background-color: #006796;
  border-color: #006796;
}
.orderbutton .itemcard_order_submit_button:active {
  background-color: #004463;
  border-color: #004463;
}
.orderbutton .itemcard_order_submit_button_inactive {
  display: inline-block;
  vertical-align: middle;
  padding: 9px 20px 11px 20px;
  line-height: 18px;
  font-size: 18px;
  min-width: 200px;
  border: 1px solid #5B5B5B;
  color: #5B5B5B;
  cursor: pointer;
  background-color: #ffffff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  font-weight: 400;
  background-color: #f0f0f0;
  color: #757579;
  border-color: #f0f0f0;
  cursor: not-allowed;
}
.orderbutton .itemcard_order_submit_button_inactive:hover,
.orderbutton .itemcard_order_submit_button_inactive:focus {
  background-color: #008AC9;
  border-color: #008AC9;
  color: #ffffff;
  text-decoration: none;
}
.orderbutton .itemcard_order_submit_button_inactive:active {
  background-color: #006796;
  border-color: #006796;
  color: #ffffff;
  text-decoration: none;
}
.orderbutton .itemcard_order_submit_button_inactive:hover,
.orderbutton .itemcard_order_submit_button_inactive:focus,
.orderbutton .itemcard_order_submit_button_inactive:active {
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  color: #757579;
}
.orderbutton .quantity input {
  border-color: #5B5B5B;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
}
.labelFormGroup {
  margin: 0;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #5B5B5B;
  margin-bottom: 5px;
}

.jako input,
.jako select,
.jako textarea,
.jako .inputHelper {
  background-color: #ffffff;
  padding: 7px 15px 9px 15px;
  color: #5B5B5B;
  border: 1px solid #BFBFBF;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  line-height: 22px;
  font-size: 17px;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  display: block;
  width: 100%;
}
input:not(:disabled):active,
select:not(:disabled):active,
textarea:not(:disabled):active,
.inputHelper:not(:disabled):active,
input:not(:disabled):focus,
select:not(:disabled):focus,
textarea:not(:disabled):focus,
.inputHelper:not(:disabled):focus {
  outline: 0;
  border-color: #008AC9;
}
input.error,
select.error,
textarea.error,
.inputHelper.error {
  border-color: #C91226;
}
input:disabled,
select:disabled,
textarea:disabled,
.inputHelper:disabled {
  background-color: #f0f0f0;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
input[type='date'],
input[type='time'] {
  -webkit-appearance: none;
  text-align: left;
  min-height: 40px;
}
input[type="file"] {
  padding: 0;
  border: 0;
}
input[type="button"],
input[type="submit"] {
  width: auto;
}
.radio input[type="radio"] {
  margin-top: 12px;
}
textarea {
  resize: vertical;
  min-height: 150px;
}
.inputGroupAddon {
  position: relative;
  width: 100%;
  max-width: 100px;
}
.inputGroupAddon input {
  padding-right: 25px;
}
.inputGroupAddon__addon {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  flex-shrink: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  justify-content: center;
  width: 25px;
}
.inputGroupAddon__addon:before,
.inputGroupAddon__addon:after {
  display: none;
}
.select_body {
  background-color: #ffffff;
  cursor: pointer;
  display: inline-block;
  width: auto;
  position: relative;
}
.select_body:after {
  content: "\E032";
  font-family: "icons";
  position: absolute;
  right: 15px;
  top: 50%;
  line-height: 20px;
  margin-top: -10px;
  font-size: 20px;
  color: #5B5B5B;
  pointer-events: none;
}
.select_body select {
  color: #5B5B5B;
  border-color: #BFBFBF;
  cursor: pointer;
  padding-right: 40px;
  -o-appearance: none;
  -khtml-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
}
.select_body select::-ms-expand {
  display: none;
}
.select_body.focus select {
  border-color: #008AC9;
}
.select_body.disabled select {
  cursor: default;
}
.form-group,
.form-group-set {
  margin-bottom: 20px;
}
.form-group input,
.form-group textarea,
.form-group .select_body,
.form-group-set input,
.form-group-set textarea,
.form-group-set .select_body {
  width: 100%;
}
.form-group.has-danger input,
.form-group-set.has-danger input {
  border-color: #C91226;
  color: #C91226;
}
.form-group label,
.form-group-set label {
  margin: 0;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #5B5B5B;
  margin-bottom: 5px;
}
.form-group label a,
.form-group-set label a {
  text-underline-position: auto;
}
@media (max-width: 767px) {
  .form-group,
  .form-group-set {
    margin-bottom: 10px;
  }
}
.form-group--with-button {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.form-group--with-button:before,
.form-group--with-button:after {
  display: none;
}
.form-group--with-button > input {
  width: auto;
  flex-grow: 1;
  flex-shrink: 0;
  width: calc(100% - 130px);
  width: -moz-calc(100% - 130px);
  width: -webkit-calc(100% - 130px);
  width: -o-calc(100% - 130px);
}
.form-group--with-button .button {
  width: 130px;
  flex-shrink: 1;
  min-width: 0;
  margin-left: 10px;
}
@media (max-width: 480px) {
  .form-group--with-button {
    flex-wrap: wrap;
  }
  .form-group--with-button > input {
    width: 100%;
  }
  .form-group--with-button .button {
    width: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1024px) and (max-width: 1249px) {
  .col-md-4 .form-group--with-button .button {
    width: 100%;
    margin-left: 0;
  }
}
.checkbox-group {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .checkbox-group {
    margin-bottom: 10px;
  }
}
.specialradiobox,
.specialcheckbox {
  position: relative;
  line-height: 20px;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 10px;
  padding-left: 30px;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  font-size: 15px;
  color: #5B5B5B;
}
.specialradiobox label,
.specialcheckbox label {
  font-style: normal;
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border: 1px solid #5B5B5B;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  top: 50%;
  margin-top: -9px;
  display: block;
  pointer-events: none;
  margin-bottom: 0;
  font-family: "franklin-gothic-urw", Helvetica, Arial, sans-serif;
}
.specialradiobox label:after,
.specialcheckbox label:after {
  content: " ";
  pointer-events: none;
  position: absolute;
  width: 10px;
  height: 10px;
  left: 4px;
  top: 4px;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -webkit-transition: opacity 0.1s ease-in;
  -moz-transition: opacity 0.1s ease-in;
  -ms-transition: opacity 0.1s ease-in;
  -o-transition: opacity 0.1s ease-in;
  background-color: #008AC9;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  display: block;
}
.specialradiobox input,
.specialcheckbox input {
  position: absolute;
  width: 1px;
  height: 1px;
  left: 10px;
  top: 50%;
  margin-top: 10px;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  cursor: pointer;
  margin: 0;
}
.specialradiobox input:checked + label:after,
.specialcheckbox input:checked + label:after {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}
.specialradiobox input:disabled + label:after,
.specialcheckbox input:disabled + label:after {
  color: #a8a8a8;
}
.specialradiobox:active,
.specialcheckbox:active {
  color: #008AC9;
}
.specialradiobox:active label:after,
.specialcheckbox:active label:after {
  -webkit-opacity: 0.7;
  -moz-opacity: 0.7;
  opacity: 0.7;
}
.specialradiobox:active input:disabled:not(:checked) + label:after,
.specialcheckbox:active input:disabled:not(:checked) + label:after {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
}
.specialradiobox.disabled,
.specialcheckbox.disabled {
  cursor: default;
  color: #5B5B5B;
}
.specialcheckbox label {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.specialcheckbox label:after {
  left: -2px;
  content: "\E040";
  font-family: "icons";
  color: #008AC9;
  top: -1px;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  line-height: 20px;
  -webkit-transition: opacity 0.1s ease-in;
  -moz-transition: opacity 0.1s ease-in;
  -ms-transition: opacity 0.1s ease-in;
  -o-transition: opacity 0.1s ease-in;
  font-size: 22px;
  background-color: transparent;
}
.specialcheckbox input {
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
}
.form-groups-inline {
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.form-groups-inline:before,
.form-groups-inline:after {
  display: none;
}
.form-groups-inline > .form-group,
.form-groups-inline > .form-group-set {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .form-groups-inline {
    margin-bottom: 0px;
  }
}
.form-group-set {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}
.form-group-set:before,
.form-group-set:after {
  display: none;
}
.form-group-set > label {
  padding-left: 5px;
  padding-right: 5px;
}
.form-group-set .form-group,
.form-group-set .form-group-set {
  margin-bottom: 0;
  flex-grow: 1;
  padding-left: 5px;
  padding-right: 5px;
}
.form-group-set .form-group-set-item__small {
  flex-shrink: 1;
  flex-grow: 0;
  width: 130px;
}
@media (max-width: 767px) {
  .form-group-set > label {
    width: 100%;
  }
  .form-group-set .form-group-set-item__small {
    width: 100px;
  }
}
@media (min-width: 481px) {
  .form-label-left .form-group:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group),
  .form-label-left .form-group-set:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group) {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .form-label-left .form-group:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group):before,
  .form-label-left .form-group:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group):after,
  .form-label-left .form-group-set:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group):before,
  .form-label-left .form-group-set:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group):after {
    display: none;
  }
  .form-label-left .form-group:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group) > label,
  .form-label-left .form-group-set:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group) > label {
    width: 150px;
    padding-right: 10px;
    flex-shrink: 0;
  }
  .form-label-left .form-group:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group) > .select_body,
  .form-label-left .form-group:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group) > input,
  .form-label-left .form-group:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group) > textarea,
  .form-label-left .form-group:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group) > .label-filename,
  .form-label-left .form-group:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group) > .selectDropdown,
  .form-label-left .form-group:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group) > .inputButtonForm,
  .form-label-left .form-group-set:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group) > .select_body,
  .form-label-left .form-group-set:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group) > input,
  .form-label-left .form-group-set:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group) > textarea,
  .form-label-left .form-group-set:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group) > .label-filename,
  .form-label-left .form-group-set:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group) > .selectDropdown,
  .form-label-left .form-group-set:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group) > .inputButtonForm {
    flex-grow: 1;
    width: auto;
  }
  .form-label-left .inputButtonForm__input {
    width: 100%;
  }
  .form-label-left .form-group.pass-strength-visible,
  .form-label-left .form-group-set.pass-strength-visible {
    flex-wrap: wrap !important;
  }
  .form-label-left .form-group.pass-strength-visible .pass-wrapper,
  .form-label-left .form-group-set.pass-strength-visible .pass-wrapper {
    margin-top: 10px;
    width: 100%;
    padding-left: 150px;
  }
  .form-label-left .form-group-button {
    padding-left: 150px;
  }
  .form-label-left .form-group-button .button {
    width: auto;
  }
  .form-label-left .form-group-set {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .form-label-left .form-group-set:before,
  .form-label-left .form-group-set:after {
    display: none;
  }
  .form-label-left .form-group-set > label {
    width: 150px;
    padding-right: 10px;
    flex-shrink: 1;
  }
  .form-label-left .checkbox-group,
  .form-label-left .radiobox-group {
    padding-left: 150px;
  }
}
.modal .form-group,
.modal .checkbox-group,
.modal .form-group-set,
.modal .form-group-set {
  margin-bottom: 10px;
}
.modal .form-group-set .form-group,
.modal .form-group-set .form-group-set {
  margin-bottom: 0;
}
.table_area .form-group,
.table_area .form-group-set {
  margin-bottom: 0;
}
.table_area .form-groups-inline {
  margin-bottom: 0;
}
.inputfile {
  width: 1px !important;
  height: 1px !important;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
}
.inputfile + label {
  background-color: #ffffff;
  padding: 7px 15px 9px 15px;
  color: #5B5B5B;
  border: 1px solid #BFBFBF;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  line-height: 22px;
  font-size: 17px;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  display: block;
  width: 100%;
  border-color: #141414;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: not-allowed;
  display: inline-block;
  overflow: hidden;
  max-width: none;
  padding-left: 40px;
  margin-bottom: 0;
  position: relative;
  background-color: #f0f0f0;
}
.inputfile + label:not(:disabled):active,
.inputfile + label:not(:disabled):focus {
  outline: 0;
  border-color: #008AC9;
}
.inputfile + label.error {
  border-color: #C91226;
}
.inputfile + label:disabled {
  background-color: #f0f0f0;
}
.inputfile + label:before,
.inputfile + label:after {
  content: " ";
  position: absolute;
  left: 10px;
  top: 50%;
  display: block;
  width: 16px;
  height: 2px;
  margin-top: -1px;
  background-color: #5B5B5B;
}
.inputfile + label:after {
  width: 2px;
  height: 16px;
  margin-top: -8px;
  margin-left: 7px;
}
.inputfile:not(:disabled) + label {
  cursor: pointer;
  background-color: #ffffff;
}
.inputfile:not(:disabled) + label:active,
.inputfile:not(:disabled) + label:focus {
  outline: 0;
}
.inputfile:not(:disabled) + label:hover:before,
.inputfile:not(:disabled) + label:hover:after {
  background-color: #008AC9;
}
.inputfile:not(:disabled) + label:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-color: #282828;
}
.inputfile-group {
  position: relative;
}
.inputButtonForm {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.inputButtonForm:before,
.inputButtonForm:after {
  display: none;
}
.inputButtonForm .form-group,
.inputButtonForm .form-group-set {
  margin-bottom: 0;
}
.inputButtonForm__input {
  width: calc(100% - 140px);
  width: -moz-calc(100% - 140px);
  width: -webkit-calc(100% - 140px);
  width: -o-calc(100% - 140px);
}
.inputButtonForm__input label {
  display: block;
  width: 100%;
}
.inputButtonForm__button {
  width: 140px;
  flex-grow: 1;
  flex-shrink: 0;
  padding-left: 10px;
}
.inputButtonForm__button .button {
  width: 100%;
  min-width: 0;
  margin-bottom: 0;
}
@media (max-width: 480px) {
  .inputButtonForm {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .inputButtonForm__input {
    width: 100%;
  }
  .inputButtonForm__button {
    width: 100%;
    padding-left: 0;
  }
}
.inputButtonForm--small .inputButtonForm__input {
  width: 100%;
  flex-grow: 1;
}
.inputButtonForm--small .inputButtonForm__button {
  width: 50px;
  flex-grow: 0;
  flex-shrink: 0;
}
.inputButtonForm--small .inputButtonForm__button .button {
  padding: 0;
  line-height: 38px;
}
.inputButtonForm--small .inputButtonForm__button .button .icon {
  display: block;
  line-height: 38px;
}
@media (max-width: 480px) {
  .inputButtonForm--small {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .inputButtonForm--small .inputButtonForm__button {
    padding-left: 10px;
  }
}
.selectDropdown {
  position: relative;
}
.selectDropdown select {
  display: none;
}
.selectDropdown__headline {
  padding: 7px 15px 9px 15px;
  color: #5B5B5B;
  border: 1px solid #BFBFBF;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  line-height: 22px;
  font-size: 17px;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  border-color: #141414;
  background-color: #ffffff;
  cursor: pointer;
  display: inline-block;
  width: auto;
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.selectDropdown__headline:not(:disabled):active,
.selectDropdown__headline:not(:disabled):focus {
  outline: 0;
  border-color: #008AC9;
}
.selectDropdown__headline.error {
  border-color: #C91226;
}
.selectDropdown__headline:disabled {
  background-color: #f0f0f0;
}
.selectDropdown__headline:after {
  content: "\E032";
  font-family: "icons";
  position: absolute;
  right: 15px;
  top: 50%;
  line-height: 20px;
  margin-top: -10px;
  font-size: 20px;
  color: #5B5B5B;
  pointer-events: none;
}
.selectDropdown__headline select {
  color: #5B5B5B;
  border-color: #BFBFBF;
  cursor: pointer;
  padding-right: 40px;
  -o-appearance: none;
  -khtml-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
}
.selectDropdown__headline select::-ms-expand {
  display: none;
}
.selectDropdown__headline.focus select {
  border-color: #008AC9;
}
.selectDropdown__headline.disabled select {
  cursor: default;
}
.selectDropdown__headline.active:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}
.selectDropdown__content {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 100;
  display: none;
  background-color: #ffffff;
  border: 1px solid #BFBFBF;
  border-top: 0;
  height: var(--vh);
  max-height: 200px;
  overflow-y: auto;
  -webkit-box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.2);
}
.selectDropdown__item {
  padding: 7px 15px;
  border-bottom: 1px solid #BFBFBF;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  color: #5B5B5B;
  text-decoration: none;
}
.selectDropdown__item:hover,
.selectDropdown__item.active {
  background-color: #f0f0f0;
  text-decoration: none;
}
.selectDropdown__item:last-child {
  border-bottom: 0;
}
.selectDropdown--color .selectDropdown__headline span,
.selectDropdown--color .selectDropdown__item span {
  width: 18px;
  height: 18px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.selectDropdown--images .selectDropdown__headline {
  padding-top: 3px;
  padding-bottom: 3px;
}
.selectDropdown--images .selectDropdown__headline,
.selectDropdown--images .selectDropdown__item {
  padding-top: 3px;
  padding-bottom: 3px;
}
.selectDropdown--images .selectDropdown__headline span.img,
.selectDropdown--images .selectDropdown__item span.img {
  max-width: 100%;
  position: relative;
  width: 32px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  background-color: #ffffff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  overflow: hidden;
}
.selectDropdown--images .selectDropdown__headline span.img img,
.selectDropdown--images .selectDropdown__item span.img img {
  bottom: 0;
  height: auto;
  left: 0;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.selectDropdown--images .selectDropdown__headline span.img:before,
.selectDropdown--images .selectDropdown__item span.img:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.bigDropdown {
  background-color: #f0f0f0;
  margin-bottom: 20px;
}
.bigDropdown__headline {
  padding: 15px 45px 15px 15px;
  position: relative;
  font-weight: 700;
  cursor: pointer;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  font-size: 17px;
}
.bigDropdown__headline:after {
  font-family: "icons";
  content: "\E032";
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -15px;
  text-align: center;
  font-weight: 400;
  width: 30px;
  line-height: 30px;
  font-size: 14px;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}
.bigDropdown__headline:hover {
  color: #008AC9;
}
.bigDropdown__headline.active:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}
.bigDropdown__content {
  padding: 0 15px 15px 15px;
  display: none;
}
.input-group .input-group-addon {
  border-radius: 0;
  background-color: #ffffff;
}
.contactform {
  display: flex;
  flex-direction: column;
}
.contactform .form-area:not(.form-area--active) {
  display: none;
}
.contactform .form-progressbar__step--active {
  font-weight: 700;
}
.contactform .form-area {
  order: 2;
  flex-grow: 1;
}
.contactform .form-area:not(.form-area--active) {
  display: none;
}
.contactform .form-area.form-area-1 .step_buttons {
  justify-content: flex-end;
}
.contactform .form-progressbar {
  order: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: relative;
  width: 100%;
}
.contactform .form-progressbar:after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  width: calc(100% - 40px);
  width: -moz-calc(100% - 40px);
  width: -webkit-calc(100% - 40px);
  width: -o-calc(100% - 40px);
  height: 2px;
  background: #5B5B5B;
}
.contactform .form-progressbar__step {
  width: 38px;
  height: 38px;
  line-height: 34px;
  border: 2px solid #5B5B5B;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  z-index: 2;
  font-size: 18px;
}
.contactform .form-progressbar__step--active {
  background: #008AC9;
  color: #ffffff;
  border: 2px solid #008AC9;
}
.contactform strong {
  font-size: 2rem;
  line-height: 3rem;
}
.contactform .specialcheckbox a {
  color: #ffffff;
}
.contactform .specialcheckbox a:hover {
  color: #5B5B5B;
}
.contactform .step_buttons {
  order: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 480px) {
  .contactform .step_buttons {
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
}
* {
  box-sizing: border-box;
}
.configurator__container {
  display: grid;
  grid-template-areas: "main" "sidebarContent" "sidebarSteps" "bottom";
  grid-template-columns: 1fr;
  grid-template-rows: calc(var(--vh) - 136px) 0 84px 52px;
}

.configurator__mainArea {
  grid-area: main;
  position: relative;
  padding: 20px;
  overflow: hidden;
}
.configurator__mainArea .jfnet-mainArea__controls {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.configurator__mainArea .mainArea__canvas {
  position: relative;
  z-index: 3;
}
.configurator__mainArea .mainArea__background {
  background: #fff;
  opacity: 0.3;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.configurator__mainArea .mainArea__close {
  position: absolute;
  right: 20px;
  top: 5px;
  font-size: 40px;
  z-index: 100;
  cursor: pointer;
}
.configurator__mainArea .mainArea__close:hover {
  color: #C91226;
}
.configurator__mainArea .mainArea__logo {
  position: absolute;
  top: 20px;
  z-index: 100;
}

.configurator__mainArea .mainArea__menuControl {
  position: absolute;
  top: 80px;
  z-index: 100;
}

.configurator__sidebarSteps {
  grid-area: sidebarSteps;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  border-right: none;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}
.simplebar-track.simplebar-vertical {
  background-color: #F0F0F0  !important;
  width: 7px !important;
  border-radius: 6px  !important;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #C2C2C2  !important;
  background-color: #C2C2C2  !important;
  border-radius: 6px  !important;
}
.configurator__sidebarSteps .simplebar-content {
  display: flex;
}
.configurator__sidebarSteps .simplebar-track.simplebar-horizontal {
  background-color: #F0F0F0;
  border-radius: 4px;
  height: 7px;
}
.configurator__sidebarSteps .simplebar-scrollbar:before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #C2C2C2;
  background-color: #C2C2C2;
  border-radius: 4px;
}
.pattern-list-wrapper .simplebar-track.simplebar-horizontal {
  background-color: #F0F0F0;
  border-radius: 4px;
  height: 7px;
}
.pattern-list-wrapper .simplebar-scrollbar:before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #C2C2C2;
  background-color: #C2C2C2;
  border-radius: 4px;
}
.text-func-list-wrapper .simplebar-track.simplebar-horizontal {
  background-color: #F0F0F0;
  border-radius: 4px;
  height: 7px;
}
.text-func-list-wrapper .simplebar-scrollbar:before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #C2C2C2;
  background-color: #C2C2C2;
  border-radius: 4px;
}
.configurator__sidebarSteps .custom-scrollbars__content {
  display: flex;
  overflow-y: hidden;
}
.configurator__sidebarSteps .sidebarStep__item {
  position: relative;
  color: #c3c3c3;
  width: 70px;
  height: 68px;
  flex-shrink: 0;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-bottom: 2px solid #c3c3c3;
}
.configurator__sidebarSteps .sidebarStep__item:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #c3c3c3;
  transition: opacity 300ms, transform 300ms;
  opacity: 1;
  transform: scale(0);
  transform-origin: center;
}
.configurator__sidebarSteps .sidebarStep__item.active {
  color: #008AC9;
  pointer-events: none;
  border-bottom: 2px solid transparent;
}
.configurator__sidebarSteps .sidebarStep__item.active:after {
  transform: scale(1);
  height: 2px;
  background-color: #008AC9;
}
.configurator__sidebarSteps .sidebarStep__item:hover,
.configurator__sidebarSteps .sidebarStep__item:focus {
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  cursor: pointer;
  color: #141414;
  border-bottom: 2px solid transparent;
}
.configurator__sidebarSteps .sidebarStep__item:hover:after,
.configurator__sidebarSteps .sidebarStep__item:focus:after {
  transform: scale(1);
  height: 2px;
  background-color: #141414;
}
.configurator__sidebarSteps .sidebarStep__icon {
  font-size: 3rem;
  line-height: 1;
}
.configurator__sidebarSteps .sidebarStep__description {
  font-size: 1.4rem;
  text-align: center;
}

.configurator__sidebarContent {
  grid-area: sidebarContent;
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

.configurator__sidebarContent .form-group-wrapper {
  margin-bottom: 20px;
  border-bottom: 2px solid #f0f0f0;
}
.configurator__bottomArea {
  grid-area: bottom;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  background: #ffffff;

  border-top: none;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
}

.configurator__bottomArea .bottomArea--left .bottomBar__item.saveItem {
  width: 100%;
  height: 52px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #F0F0F0;
}

.configurator__bottomArea .bottomArea--right .button_primary {
  width: 100%;
  height: 52px;
  font-size: 1.5rem;
  padding-left: 15px;
  padding-right: 15px;
}

.bottomArea--left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  width: 100%;
  height: 52px;
}

.bottomArea--right {
  flex-grow: 1;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 52px;
}

.bottomBar__item {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 110px;
  color: #141414;
}
.bottomBar__item .icon {
  font-size: 28px;
  margin-bottom: 0;
  margin-right: 10px;
  margin-left: -3px;
}

.bottomBar__item:not(.itemsAdded):hover {
  color: #008AC9;
}
.bottomBar__slideOut {
  height: 0;
  background: #ffffff;
  position: fixed;
  bottom: -100%;
  left: 0;
  right: 0;
  z-index: 2;
  padding: 20px 20px 10px 20px;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
}
.bottomBar__slideOut.active {
  bottom: 60px;
  opacity: 1;
  height: auto;
}
.slideOut__close {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 32px;
  z-index: 99;
}

.loadingAnimation--full {
  background-color: #BEBEBE;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loadingAnimation__spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 240px;
  cursor: pointer;
}
.loadingAnimation__spinner .loadingAnimation_ring {
  position: relative;
  box-sizing: border-box;
}
.loadingAnimation__spinner .loadingAnimation_ring div:not(.icon, .ringPlaceholder) {
  display: block;
  position: absolute;
  width: 120px;
  height: 120px;
  border: 4px solid #ffffff;
  border-radius: 50%;
  animation: loadingRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ffffff transparent transparent transparent;
  z-index: 3;
}
.loadingAnimation__spinner .loadingAnimation_ring div:not(.icon, .ringPlaceholder):nth-child(1) {
  animation-delay: -0.45s;
}
.loadingAnimation__spinner .loadingAnimation_ring div:not(.icon, .ringPlaceholder):nth-child(2) {
  animation-delay: -0.3s;
}
.loadingAnimation__spinner .loadingAnimation_ring div:not(.icon, .ringPlaceholder):nth-child(3) {
  animation-delay: -0.15s;
}
.loadingAnimation__spinner .icon {
  font-size: 120px;
  line-height: 1;
  transform: scale(1);
  animation: pulse 2s infinite;
  margin-top: -8px;
}
.loadingAnimation__spinner .ringPlaceholder {
  width: 120px;
  height: 120px;
  border: 4px solid rgba(224, 224, 224, 0.7);
  border-radius: 50%;
  position: absolute;
  top: 0;
  z-index: 1;
}
@keyframes loadingRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.85);
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.85);
  }
}
.slideContent.slideContent__borderTop {
  border-top: 2px solid #f0f0f0;
  padding-top: 10px;
}
.slideContent .slideContainer {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 2px solid #f0f0f0;
}
.slideContent .slideContainer__headline {
  font-weight: 600;
  color: #141414;
  padding-right: 50px;
  display: flex;
  justify-content: space-between;
}
.slideContent .slideContainer__selectedOutput {
  font-weight: 400;
}
.slideContent .slideContainer .slideContent__show,
.slideContent .slideContainer .slideContent__show--small {
  position: absolute;
  top: 25px;
  right: 0;
  width: 45px;
  height: 58px;
  font-weight: 600;
  color: #141414;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}
.slideContent .slideContainer .slideContent__show--small,
.slideContent .slideContainer .slideContent__show--small--small {
  top: -5px;
  width: 45px;
  height: 30px;
}
.slideContent .slideContainer .slideContent__show--small .slideContent__plus,
.slideContent .slideContainer .slideContent__show--small--small .slideContent__plus {
  width: 30px!important;
  height: 30px!important;
}
.slideContent .slideContainer .slideContent__show:hover,
.slideContent .slideContainer .slideContent__show--small:hover,
.slideContent .slideContainer .slideContent__show:active,
.slideContent .slideContainer .slideContent__show--small:active,
.slideContent .slideContainer .slideContent__show:focus,
.slideContent .slideContainer .slideContent__show--small:focus {
  color: #141414;
}
.slideContent .slideContainer .slideContent__show .slideContent__plus,
.slideContent .slideContainer .slideContent__show--small .slideContent__plus {
  position: relative;
  width: 45px;
  height: 45px;
}
.slideContent .slideContainer .slideContent__show .slideContent__plus .horizontal,
.slideContent .slideContainer .slideContent__show--small .slideContent__plus .horizontal {
  position: absolute;
  background-color: #141414;
  width: 22px;
  height: 2px;
  left: 50%;
  margin-left: -11px;
  top: 50%;
  margin-top: -1px;
  transition: all 0.5s ease-in-out;
  transform: rotate(-90deg);
  opacity: 1;
}
.slideContent .slideContainer .slideContent__show .slideContent__plus .vertical,
.slideContent .slideContainer .slideContent__show--small .slideContent__plus .vertical {
  position: absolute;
  background-color: #141414;
  width: 2px;
  height: 22px;
  left: 50%;
  margin-left: -1px;
  top: 50%;
  margin-top: -11px;
  transition: all 0.5s ease-in-out;
  transform: rotate(-90deg);
}
.slideContent .slideContainer .slideContent__show.active,
.slideContent .slideContainer .slideContent__show--small.active {
  opacity: 1;
}
.slideContent .slideContainer .slideContent__show.active .vertical,
.slideContent .slideContainer .slideContent__show--small.active .vertical {
  transition: all 0.5s ease-in-out;
  transform: rotate(90deg);
}
.slideContent .slideContainer .slideContent__show.active .horizontal,
.slideContent .slideContainer .slideContent__show--small.active .horizontal {
  transition: all 0.5s ease-in-out;
  transform: rotate(90deg);
  opacity: 0;
}
.slideContent .slideContainer .slideContent__show.active .icon:before,
.slideContent .slideContainer .slideContent__show--small.active .icon:before {
  content: "\E01E";
}
.slideContent .slideContainer .slideContent__show.active + .slideContent__contentContainer,
.slideContent .slideContainer .slideContent__show--small.active + .slideContent__contentContainer {
  opacity: 1;
  z-index: 1;
  height: 100%;
  visibility: visible;
}
.slideContent .slideContainer .slideContent__contentContainer {
  font-size: 1.4rem;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  height: 0;
  -webkit-transition: 0.5s all ease-in-out;
  -moz-transition: 0.5s all ease-in-out;
  -ms-transition: 0.5s all ease-in-out;
  -o-transition: 0.5s all ease-in-out;
}

.pattern-tab.slideContent .slideContainer .slideContent__contentContainer {
  font-size: 1.4rem;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  height: 0;
}
.button,
.btn {
  display: inline-block;
  vertical-align: middle;
  padding: 9px 20px 11px 20px;
  line-height: 18px;
  font-size: 18px;
  min-width: 200px;
  border: 1px solid #5B5B5B;
  color: #5B5B5B;
  cursor: pointer;
  background-color: #ffffff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  font-weight: 400;
}
.button:hover,
.btn:hover,
.button:focus,
.btn:focus {
  background-color: #008AC9;
  border-color: #008AC9;
  color: #ffffff;
  text-decoration: none;
}
.button:active,
.btn:active {
  background-color: #006796;
  border-color: #006796;
  color: #ffffff;
  text-decoration: none;
}
.button_primary,
.btn-primary {
  display: inline-block;
  vertical-align: middle;
  padding: 9px 20px 11px 20px;
  line-height: 18px;
  font-size: 18px;
  min-width: 200px;
  border: 1px solid #5B5B5B;
  color: #5B5B5B;
  cursor: pointer;
  background-color: #ffffff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  font-weight: 400;
  background-color: #008AC9;
  color: #ffffff;
  border-color: #008AC9;
}
.button_primary:hover,
.btn-primary:hover,
.button_primary:focus,
.btn-primary:focus {
  background-color: #008AC9;
  border-color: #008AC9;
  color: #ffffff;
  text-decoration: none;
}
.button_primary:active,
.btn-primary:active {
  background-color: #006796;
  border-color: #006796;
  color: #ffffff;
  text-decoration: none;
}
.button_primary:hover,
.btn-primary:hover,
.button_primary:focus,
.btn-primary:focus {
  background-color: #006796;
  border-color: #006796;
}
.button_primary:active,
.btn-primary:active {
  background-color: #004463;
  border-color: #004463;
}
.button_second,
.btn-second {
  display: inline-block;
  vertical-align: middle;
  padding: 9px 20px 11px 20px;
  line-height: 18px;
  font-size: 18px;
  min-width: 200px;
  border: 1px solid #5B5B5B;
  color: #5B5B5B;
  cursor: pointer;
  background-color: #ffffff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  font-weight: 400;
  background-color: #008AC9;
  color: #ffffff;
  border-color: #008AC9;
}
.button_second:hover,
.btn-second:hover,
.button_second:focus,
.btn-second:focus {
  background-color: #008AC9;
  border-color: #008AC9;
  color: #ffffff;
  text-decoration: none;
}
.button_second:active,
.btn-second:active {
  background-color: #006796;
  border-color: #006796;
  color: #ffffff;
  text-decoration: none;
}
.button_second:hover,
.btn-second:hover,
.button_second:focus,
.btn-second:focus {
  background-color: #006796;
  border-color: #006796;
}
.button_second:active,
.btn-second:active {
  background-color: #004463;
  border-color: #004463;
}
.button_warning,
.btn-warning {
  display: inline-block;
  vertical-align: middle;
  padding: 9px 20px 11px 20px;
  line-height: 18px;
  font-size: 18px;
  min-width: 200px;
  border: 1px solid #5B5B5B;
  color: #5B5B5B;
  cursor: pointer;
  background-color: #ffffff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  font-weight: 400;
  background-color: #FFC400;
  color: #ffffff;
  border-color: #FFC400;
}
.button_warning:hover,
.btn-warning:hover,
.button_warning:focus,
.btn-warning:focus {
  background-color: #008AC9;
  border-color: #008AC9;
  color: #ffffff;
  text-decoration: none;
}
.button_warning:active,
.btn-warning:active {
  background-color: #006796;
  border-color: #006796;
  color: #ffffff;
  text-decoration: none;
}
.button_warning:hover,
.btn-warning:hover,
.button_warning:focus,
.btn-warning:focus {
  background-color: #b38900;
  border-color: #b38900;
}
.button_warning:active,
.btn-warning:active {
  background-color: #806200;
  border-color: #806200;
}
.button_success,
.btn-success {
  display: inline-block;
  vertical-align: middle;
  padding: 9px 20px 11px 20px;
  line-height: 18px;
  font-size: 18px;
  min-width: 200px;
  border: 1px solid #5B5B5B;
  color: #5B5B5B;
  cursor: pointer;
  background-color: #ffffff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  font-weight: 400;
  background-color: #1EA515;
  color: #ffffff;
  border-color: #1EA515;
}
.button_success:hover,
.btn-success:hover,
.button_success:focus,
.btn-success:focus {
  background-color: #008AC9;
  border-color: #008AC9;
  color: #ffffff;
  text-decoration: none;
}
.button_success:active,
.btn-success:active {
  background-color: #006796;
  border-color: #006796;
  color: #ffffff;
  text-decoration: none;
}
.button_success:hover,
.btn-success:hover,
.button_success:focus,
.btn-success:focus {
  background-color: #12610c;
  border-color: #12610c;
}
.button_success:active,
.btn-success:active {
  background-color: #093407;
  border-color: #093407;
}
.button_info,
.btn-info {
  display: inline-block;
  vertical-align: middle;
  padding: 9px 20px 11px 20px;
  line-height: 18px;
  font-size: 18px;
  min-width: 200px;
  border: 1px solid #5B5B5B;
  color: #5B5B5B;
  cursor: pointer;
  background-color: #ffffff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  font-weight: 400;
  background-color: #BFBFBF;
  color: #ffffff;
  border-color: #BFBFBF;
}
.button_info:hover,
.btn-info:hover,
.button_info:focus,
.btn-info:focus {
  background-color: #008AC9;
  border-color: #008AC9;
  color: #ffffff;
  text-decoration: none;
}
.button_info:active,
.btn-info:active {
  background-color: #006796;
  border-color: #006796;
  color: #ffffff;
  text-decoration: none;
}
.button_info:hover,
.btn-info:hover,
.button_info:focus,
.btn-info:focus {
  background-color: #999999;
  border-color: #999999;
}
.button_info:active,
.btn-info:active {
  background-color: #7f7f7f;
  border-color: #7f7f7f;
}
.button_gray,
.btn-gray {
  display: inline-block;
  vertical-align: middle;
  padding: 9px 20px 11px 20px;
  line-height: 18px;
  font-size: 18px;
  min-width: 200px;
  border: 1px solid #5B5B5B;
  color: #5B5B5B;
  cursor: pointer;
  background-color: #ffffff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  font-weight: 400;
  background-color: #BFBFBF;
  color: #ffffff;
  border-color: #BFBFBF;
}
.button_gray:hover,
.btn-gray:hover,
.button_gray:focus,
.btn-gray:focus {
  background-color: #008AC9;
  border-color: #008AC9;
  color: #ffffff;
  text-decoration: none;
}
.button_gray:active,
.btn-gray:active {
  background-color: #006796;
  border-color: #006796;
  color: #ffffff;
  text-decoration: none;
}
.button_gray:hover,
.btn-gray:hover,
.button_gray:focus,
.btn-gray:focus {
  background-color: #999999;
  border-color: #999999;
}
.button_gray:active,
.btn-gray:active {
  background-color: #7f7f7f;
  border-color: #7f7f7f;
}
.button_action,
.btn-action {
  display: inline-block;
  vertical-align: middle;
  padding: 9px 20px 11px 20px;
  line-height: 18px;
  font-size: 18px;
  min-width: 200px;
  border: 1px solid #5B5B5B;
  color: #5B5B5B;
  cursor: pointer;
  background-color: #ffffff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  font-weight: 400;
  background-color: #5B5B5B;
  color: #ffffff;
  border-color: #5B5B5B;
}
.button_action:hover,
.btn-action:hover,
.button_action:focus,
.btn-action:focus {
  background-color: #008AC9;
  border-color: #008AC9;
  color: #ffffff;
  text-decoration: none;
}
.button_action:active,
.btn-action:active {
  background-color: #006796;
  border-color: #006796;
  color: #ffffff;
  text-decoration: none;
}
.button_action:hover,
.btn-action:hover,
.button_action:focus,
.btn-action:focus {
  background-color: #008AC9;
  border-color: #008AC9;
  color: #ffffff;
}
.button_action:active,
.btn-action:active {
  background-color: #006796;
  border-color: #006796;
  color: #ffffff;
}
.button_danger,
.btn-danger {
  display: inline-block;
  vertical-align: middle;
  padding: 9px 20px 11px 20px;
  line-height: 18px;
  font-size: 18px;
  min-width: 200px;
  border: 1px solid #5B5B5B;
  color: #5B5B5B;
  cursor: pointer;
  background-color: #ffffff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  font-weight: 400;
  background-color: #C91226;
  color: #ffffff;
  border-color: #C91226;
}
.button_danger:hover,
.btn-danger:hover,
.button_danger:focus,
.btn-danger:focus {
  background-color: #008AC9;
  border-color: #008AC9;
  color: #ffffff;
  text-decoration: none;
}
.button_danger:active,
.btn-danger:active {
  background-color: #006796;
  border-color: #006796;
  color: #ffffff;
  text-decoration: none;
}
.button_danger:hover,
.btn-danger:hover,
.button_danger:focus,
.btn-danger:focus {
  background-color: #830c19;
  border-color: #830c19;
}
.button_danger:active,
.btn-danger:active {
  background-color: #540810;
  border-color: #540810;
}
.button_black,
.btn-black {
  display: inline-block;
  vertical-align: middle;
  padding: 9px 20px 11px 20px;
  line-height: 18px;
  font-size: 18px;
  min-width: 200px;
  border: 1px solid #5B5B5B;
  color: #5B5B5B;
  cursor: pointer;
  background-color: #ffffff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  font-weight: 400;
  background-color: #5B5B5B;
  color: #ffffff;
  border-color: #5B5B5B;
}
.button_black:hover,
.btn-black:hover,
.button_black:focus,
.btn-black:focus {
  background-color: #008AC9;
  border-color: #008AC9;
  color: #ffffff;
  text-decoration: none;
}
.button_black:active,
.btn-black:active {
  background-color: #006796;
  border-color: #006796;
  color: #ffffff;
  text-decoration: none;
}
.button_black:hover,
.btn-black:hover,
.button_black:focus,
.btn-black:focus {
  background-color: #008AC9;
  border-color: #008AC9;
}
.button_black:active,
.btn-black:active {
  background-color: #006796;
  border-color: #006796;
}
.button_disabled,
.btn-disabled {
  display: inline-block;
  vertical-align: middle;
  padding: 9px 20px 11px 20px;
  line-height: 18px;
  font-size: 18px;
  min-width: 200px;
  border: 1px solid #5B5B5B;
  color: #5B5B5B;
  cursor: pointer;
  background-color: #ffffff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  font-weight: 400;
  background-color: #f0f0f0;
  color: #757579;
  border-color: #f0f0f0;
  cursor: not-allowed;
}
.button_disabled:hover,
.btn-disabled:hover,
.button_disabled:focus,
.btn-disabled:focus {
  background-color: #008AC9;
  border-color: #008AC9;
  color: #ffffff;
  text-decoration: none;
}
.button_disabled:active,
.btn-disabled:active {
  background-color: #006796;
  border-color: #006796;
  color: #ffffff;
  text-decoration: none;
}
.button_disabled:hover,
.btn-disabled:hover,
.button_disabled:focus,
.btn-disabled:focus,
.button_disabled:active,
.btn-disabled:active {
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  color: #757579;
}
/*.button_more {
  position: relative;
  display:inline-block;
  font-size: 18px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 9px 0 14px 0;
  width: 200px;
  font-family: @font-family-second;
  line-height: 26px;
  letter-spacing: 0.2px;
  text-align: left;
  color: @black;
  .transition(0.1s ease-in-out);
  &:after {
    content:"";
    display: block;
    width:100%;
    height: 3px;
    background-color: @black;
    position: absolute;
    bottom:0;
    .transition(0.1s ease-in-out);
  }
  &:hover, &:focus {
    text-decoration: none;
    &:after {
      width: 20%;
    }
  }

  &:active {
    text-decoration: none;
    &:after {
      width: 20%;
    }
  }

  @media (max-width: @screen-xs-max) {
    padding-bottom: 10px;
    padding-top: 5px;
    font-size: 16px;
    line-height: 20px;
    width: 150px;
  }
}*/
.button_more {
  display: inline-block;
  vertical-align: middle;
  padding: 9px 20px 11px 20px;
  line-height: 18px;
  font-size: 18px;
  min-width: 200px;
  border: 1px solid #5B5B5B;
  color: #5B5B5B;
  cursor: pointer;
  background-color: #ffffff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  font-weight: 400;
  background-color: #5B5B5B;
  color: #ffffff;
  border-color: #5B5B5B;
}
.button_more:hover,
.button_more:focus {
  background-color: #008AC9;
  border-color: #008AC9;
  color: #ffffff;
  text-decoration: none;
}
.button_more:active {
  background-color: #006796;
  border-color: #006796;
  color: #ffffff;
  text-decoration: none;
}
.button_more:hover,
.button_more:focus {
  background-color: #008AC9;
  border-color: #008AC9;
}
.button_more:active {
  background-color: #006796;
  border-color: #006796;
}
.button_jumpmark {
  display: inline-block;
  vertical-align: middle;
  padding: 9px 20px 11px 20px;
  line-height: 18px;
  font-size: 18px;
  min-width: 200px;
  border: 1px solid #5B5B5B;
  color: #5B5B5B;
  cursor: pointer;
  background-color: #ffffff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  font-weight: 400;
  background-color: #5B5B5B;
  color: #ffffff;
  border-color: #5B5B5B;
  min-width: 0;
  width: 220px;
  max-width: 100%;
  text-align: left;
  position: relative;
  padding-right: 45px;
}
.button_jumpmark:hover,
.button_jumpmark:focus {
  background-color: #008AC9;
  border-color: #008AC9;
  color: #ffffff;
  text-decoration: none;
}
.button_jumpmark:active {
  background-color: #006796;
  border-color: #006796;
  color: #ffffff;
  text-decoration: none;
}
.button_jumpmark:hover,
.button_jumpmark:focus {
  background-color: #008AC9;
  border-color: #008AC9;
  color: #ffffff;
}
.button_jumpmark:active {
  background-color: #006796;
  border-color: #006796;
  color: #ffffff;
}
.button_jumpmark:after {
  font-family: "icons";
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  content: "\E032";
  position: absolute;
  top: 50%;
  line-height: 20px;
  width: 20px;
  margin-top: -10px;
  right: 15px;
  font-size: 16px;
  text-align: center;
}
.button--icon-left {
  position: relative;
  padding-left: 45px;
  min-width: 0;
}
.button--icon-left .icon {
  position: absolute;
  top: 50%;
  line-height: 20px;
  width: 20px;
  margin-top: -10px;
  left: 15px;
  font-size: 16px;
  text-align: center;
}
.button--icon-right {
  position: relative;
  padding-right: 45px;
  min-width: 0;
}
.button--icon-right .icon {
  position: absolute;
  top: 50%;
  line-height: 20px;
  width: 20px;
  margin-top: -10px;
  right: 15px;
  font-size: 16px;
  text-align: center;
}
.button_full {
  width: 100%;
  min-width: 0;
}
.button_next,
.button_back {
  width: 100%;
  position: relative;
  padding-right: 20px;
  padding-left: 20px;
}
.button_next:before,
.button_back:before {
  display: block;
  position: absolute;
  top: 50%;
  font-family: "icons";
  line-height: 30px;
  margin-top: -15px;
  font-size: 24px;
  font-weight: 400;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}
.button_next {
  display: inline-block;
  vertical-align: middle;
  padding: 9px 20px 11px 20px;
  line-height: 18px;
  font-size: 18px;
  min-width: 200px;
  border: 1px solid #5B5B5B;
  color: #5B5B5B;
  cursor: pointer;
  background-color: #ffffff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  font-weight: 400;
  background-color: #008AC9;
  color: #ffffff;
  border-color: #008AC9;
  text-transform: none;
  min-width: 0;
}
.button_next:hover,
.button_next:focus {
  background-color: #008AC9;
  border-color: #008AC9;
  color: #ffffff;
  text-decoration: none;
}
.button_next:active {
  background-color: #006796;
  border-color: #006796;
  color: #ffffff;
  text-decoration: none;
}
.button_next:hover,
.button_next:focus {
  background-color: #006796;
  border-color: #006796;
}
.button_next:active {
  background-color: #004463;
  border-color: #004463;
}
.button_next:before {
  content: "\E034";
  right: 0;
}
.button_next:hover:before {
  right: -3px;
}
.button_back {
  display: inline-block;
  vertical-align: middle;
  padding: 9px 20px 11px 20px;
  line-height: 18px;
  font-size: 18px;
  min-width: 200px;
  border: 1px solid #5B5B5B;
  color: #5B5B5B;
  cursor: pointer;
  background-color: #ffffff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  font-weight: 400;
  min-width: 0;
}
.button_back:hover,
.button_back:focus {
  background-color: #008AC9;
  border-color: #008AC9;
  color: #ffffff;
  text-decoration: none;
}
.button_back:active {
  background-color: #006796;
  border-color: #006796;
  color: #ffffff;
  text-decoration: none;
}
.button_back:before {
  content: "\E033";
  left: 0;
}
.button_back:hover:before {
  left: -3px;
}
.button_row,
.button_row_bottom,
.button_row_top {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: -10px;
  margin-right: -10px;
}
.button_row:after,
.button_row_bottom:after,
.button_row_top:after {
  clear: both;
  display: block;
  content: " ";
}
.button_row .button_paypalexpress,
.button_row_bottom .button_paypalexpress,
.button_row_top .button_paypalexpress {
  display: inline-block;
}
.button_row .button,
.button_row .button_paypalexpress,
.button_row_bottom .button,
.button_row_bottom .button_paypalexpress,
.button_row_top .button,
.button_row_top .button_paypalexpress {
  margin: 10px 10px;
}
.button_row--center,
.button_row_bottom--center,
.button_row_top--center {
  text-align: center;
}
@media (max-width: 480px) {
  .button_row,
  .button_row_bottom,
  .button_row_top {
    text-align: center;
  }
  .button_row .pull-left,
  .button_row .pull-right,
  .button_row_bottom .pull-left,
  .button_row_bottom .pull-right,
  .button_row_top .pull-left,
  .button_row_top .pull-right {
    float: none !important;
  }
  .button_row .button,
  .button_row_bottom .button,
  .button_row_top .button {
    min-width: 220px;
  }
}
.button_row_bottom {
  margin-bottom: 0;
}
.button_row_bottom .button,
.button_row_bottom .button_paypalexpress {
  margin-bottom: 0;
}
.button_row_top {
  margin-top: 0;
}
.button_row_top .button,
.button_row_top .button_paypalexpress {
  margin-top: 0;
}
.button-min-width {
  min-width: 270px;
}
.button--full-width {
  min-width: 0;
  width: 100%;
}
.loading {
  padding-left: 35px !important;
  cursor: default;
  position: relative;
  background-color: #008AC9;
  border-color: #008AC9;
}
.loading:after {
  position: absolute;
  top: 50%;
  line-height: 30px;
  margin-top: -15px;
  left: 9px;
  content: "\E086";
  font-family: "icons";
  font-size: 20px;
  -webkit-animation: spin 1000ms infinite ease-in-out;
  -moz-animation: spin 1000ms infinite ease-in-out;
  -ms-animation: spin 1000ms infinite ease-in-out;
}
.loading:before {
  right: -100%;
}
.loading:hover {
  background-color: #008AC9;
  border-color: #008AC9;
}
@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.bigButtons {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -40px;
}
.bigButtons:before,
.bigButtons:after {
  display: none;
}
.bigButtons__item {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 40px;
  text-align: center;
  width: 33.33333333%;
  max-width: 33.33333333%;
  flex-grow: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.bigButtons__item:before,
.bigButtons__item:after {
  display: none;
}
@media (max-width: 480px) {
  .bigButtons__item {
    width: 50%;
    max-width: 100%;
  }
}
.bigButtons__button {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #5B5B5B;
  border: 1px solid #5B5B5B;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
}
.bigButtons__button:before,
.bigButtons__button:after {
  display: none;
}
.bigButtons__button .icon {
  flex-shrink: 0;
  font-size: 35px;
  line-height: 50px;
  align-self: center;
}
.bigButtons__buttonTop {
  flex-grow: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  justify-content: center;
}
.bigButtons__buttonTop:before,
.bigButtons__buttonTop:after {
  display: none;
}
.bigButtons__buttonImgs {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}
.bigButtons__buttonImgs:before,
.bigButtons__buttonImgs:after {
  display: none;
}
.bigButtons__buttonImgs .image {
  width: 50%;
}
.bigButtons__buttonImgs .image img {
  max-width: calc(100% - 10px);
  max-width: -moz-calc(100% - 10px);
  max-width: -webkit-calc(100% - 10px);
  max-width: -o-calc(100% - 10px);
  max-height: calc(100% - 10px);
  max-height: -moz-calc(100% - 10px);
  max-height: -webkit-calc(100% - 10px);
  max-height: -o-calc(100% - 10px);
}
.bigButtons__buttonLabel {
  padding-top: 10px;
}
.bigButtons__button:hover,
.bigButtons__button:focus {
  text-decoration: none;
  border-color: #008AC9;
}
.button--link {
  border: 0;
  padding: 0;
  text-transform: none;
  background-color: transparent;
  text-decoration: underline;
  color: #757579;
}
.button--link:hover,
.button--link:focus,
.button--link:active {
  background-color: transparent;
  color: #008AC9;
}
.button--link.button--icon-left {
  padding-left: 25px;
}
.button--link.button--icon-left .icon {
  left: 0;
}
.button--link.button--icon-right {
  padding-right: 25px;
}
.button--link.button--icon-right .icon {
  right: 0;
}
.button--linkWhite {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  position: relative;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
}
.button--linkWhite:before {
  content: "-";
  width: 10px;
  margin-right: 5px;
  display: none;
  position: absolute;
  left: 0;
}
.button--linkWhite:hover {
  padding-left: 15px;
  color: #ffffff;
  text-decoration: none;
}
.button--linkWhite:hover:before {
  display: block;
}
.button_primary {
  text-transform: none;
}
#calculatePrice {
  padding-right: 30px!important;
}
#calculatePrice .icon {
  font-size: 30px!important;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
}
#calculatePrice.active .icon {
  transform: rotate(135deg);
}
.linkWithIcon--right {
  padding-right: 20px;
  padding-left: 0!important;
}
.linkWithIcon--right .icon {
  left: unset!important;
  right: 0;
}
.colorWrapper {
  display: grid;
  grid-template-columns: repeat(6, 56px);
}
.colorWrapper .itemColor {
  background: #ffffff;
  padding: 11px;
}
.colorWrapper .itemColor--bgColor {
  width: 34px;
  height: 34px;
}
.colorWrapper .itemColor:hover {
  background: #f0f0f0;
  cursor: pointer;
}
.colorWrapper .itemColor.active {
  border-bottom: 2px solid #008AC9;
  background: #f0f0f0;
}
.colorWrapper--responsive {
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
  padding-right: 20px;
}
.colorWrapper--flex-responsive{
  display: flex !important;
  padding-right: 20px;
}
.colorWrapper.colorWrapper--responsive .itemColor {
  position: relative;
  width: 66px;
  height: 66px;
}
.colorWrapper.colorWrapper--responsive .itemColor.active {
  position: relative;
  border-bottom: 0;
}
.colorWrapper.colorWrapper--responsive .itemColor.active::before {
  content: "";
  position: absolute;
  bottom: 1px;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #008AC9;
}
.colorWrapper.colorWrapper--responsive .itemColor--bgColor {
  width: 43px;
  height: 43px;
}
.colorWrapper.colorWrapper--flex-responsive .itemColor {
  width: 66px;
  height: 66px;
}
.colorWrapper.colorWrapper--flex-responsive .itemColor.active {
  position: relative;
  border-bottom: 0;
}
.colorWrapper.colorWrapper--flex-responsive .itemColor.active::before {
  content: "";
  position: absolute;
  bottom: 1px;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #008AC9;
}
.colorWrapper.colorWrapper--flex-responsive .itemColor--bgColor {
  width: 43px;
  height: 43px;
}
.colorWrapper--7cols {
  grid-template-columns: repeat(7, 56px);
  column-gap: 5px;
}
.controlsCircle {
  position: absolute;
  box-sizing: border-box;
  background-clip: padding-box;
  width: 400px;
  height: 400px;
  border-radius: 100%;
  border: 4px solid #c3c3c3;
  -webkit-mask: linear-gradient(hsla(0, 0%, 0%, 0.1), hsl(0, 0%, 0%) 90%);
  transform-origin: 50% 60%;
  transform: perspective(400px) rotateX(75deg);
  margin-bottom: 30px;
}

.controlsCircle img {
  position: absolute;
  display: flex;
  width: 80%;
  margin-top: 50%;
  transform: translateX(10%);
  justify-content: center;
  align-items: center;
}
@keyframes spinner-spin {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinner-fade {
  20% {
    opacity: 0.1;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 0.1;
  }
}
.controlBar {
  font-size: 40px;
  position: absolute;
  bottom: 105px;
  background-color: #fff;
  line-height: 1;
  color: #c3c3c3;
  display: flex;
  align-items: center;
  z-index: 20;
  left: 50%;
  transform: translateX(-50%);
}

.controlBar .icon {
  cursor: pointer;
  display: none;
}
.controlBar .icon:hover {
  color: #141414;
}
.form-group,
.form-group-set {
  position: relative;
}
.form-group.borderBottom,
.form-group-set.borderBottom {
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.form-group.fontSize .form-group--headline,
.form-group-set.fontSize .form-group--headline {
  margin-bottom: 0;
}
.form-group--headline {
  font-weight: 600;
  margin-bottom: 10px;
  color: #141414;
}
input,
select,
textarea,
.inputHelper {
  border-color: #141414;
}
.quantity.fullWidth {
  width: 100%;
}
.quantity input {
  width: calc(100% - 25px);
  width: -moz-calc(100% - 25px);
  width: -webkit-calc(100% - 25px);
  width: -o-calc(100% - 25px);
}
.quantitySizes {
  width: 120px;
}
.quantitySizes .spinner_wrapper {
  display: flex;
  align-items: flex-start;
  position: relative;
  right: unset;
  top: unset;
  margin-bottom: 0;
}
.quantitySizes .spinner_wrapper > div {
  border: none;
  width: 40px;
  height: 40px;
}

.pattern-range input[type=range] {
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}
.pattern-range input[type=range]::-webkit-slider-thumb {
  height: 15px;
  width: 15px;
  margin-top: -6px;
}
.pattern-range input[type=range]::-moz-range-thumb {
  height: 15px;
  width: 15px;
}
.pattern-range input[type=range]::-ms-thumb {
  height: 15px;
  width: 15px;
}

.pattern-range .input-range__label-container {
  display: none !important;
}

.pattern-range .input-range__slider {
  width: 1.3rem;
  height: 1.3rem;
  background-color: #008AC9;
  border-color: #008AC9;
}
.pattern-range .input-range__track--active {
  background: transparent !important;
  background-color: transparent !important;
}


/* RANGE SLIDER OVERWRITE */
input[type=range] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
  border: 0;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: #BFBFBF;
  border-radius: 5px;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #BFBFBF, 0px 0px 1px #cccccc;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #008AC9;
  cursor: pointer;
  position: relative;
  z-index: 2;
  -webkit-appearance: none;
  margin-top: -9px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #cccccc;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: #BFBFBF;
  border-radius: 5px;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #BFBFBF, 0px 0px 1px #cccccc;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #008AC9;
  cursor: pointer;
  position: relative;
  z-index: 2;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 20px 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #b2b2b2;
  border-radius: 10px;
}
input[type=range]::-ms-fill-upper {
  background: #BFBFBF;
  border-radius: 10px;
}
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #BFBFBF, 0px 0px 1px #cccccc;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #008AC9;
  cursor: pointer;
  position: relative;
  z-index: 2;
}
input[type=range]:focus::-ms-fill-lower {
  background: #BFBFBF;
}
input[type=range]:focus::-ms-fill-upper {
  background: #cccccc;
}
.labelOutputWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.labelOutputWrapper label {
  font-weight: 600;
  font-family: "franklin-gothic-urw", Helvetica, Arial, sans-serif;
  color: #141414;
}
.labelOutputWrapper output {
  align-items: flex-end;
}
datalist {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  writing-mode: vertical-lr;
  width: 100%;
}
datalist option {
  background: #c3c3c3;
  padding: 0;
  margin-top: -20px;
  margin-bottom: 5px;
  margin-left: 0;
  margin-right: 0;
  width: 1px;
  writing-mode: horizontal-tb;
  position: relative;
}
datalist option span {
  position: absolute;
  bottom: -10px;
}
.controlBarWrapper {
  display: flex;
  justify-content: space-between;
}
.controlBarWrapper .icon {
  font-size: 40px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}
.controlBarWrapper .icon:hover {
  background: #f0f0f0;
  color: #141414;
}
.controlBarWrapper.borderBottom {
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.controlBar--left {
  display: grid;
  flex-grow: 1;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
}
.controlBar--left a {
  flex-grow: 1;
  display: block;
}
@media (max-width: 1249px) {
  .controlBar--left {
    grid-gap: 10px;
  }
}
.controlBar--right {
  display: grid;
  grid-template-columns: 1fr;
}
.inputInlineBtn {
  position: relative;
}
.inputInlineBtn input {
  padding-right: 50px;
}
.inputInlineBtn__btn,
.inputInlineBtn a {
  background: transparent;
  border: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #141414;
  width: 50px;
}
.inputInlineBtn__btn:hover,
.inputInlineBtn a:hover,
.inputInlineBtn__btn:focus,
.inputInlineBtn a:focus,
.inputInlineBtn__btn:active,
.inputInlineBtn a:active {
  text-decoration: none;
  color: #008AC9;
}
.inputInlineBtn__btn .icon,
.inputInlineBtn a .icon {
  font-size: 26px;
}
.saveInput {
  width: 100%;
  margin-right: 10px;
}
.saveInput a {
  opacity: 0;
  pointer-events: none;
}
.saveInput.changed a {
  opacity: 1;
  pointer-events: auto;
}
.iconButton {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  border: 1px solid #141414;
  flex-shrink: 0;
  color: #141414;
  cursor: pointer;
}
.iconButton.delete {
  border: none;
}
.iconButton:hover {
  background: #008AC9;
  color: #ffffff;
  border-color: #008AC9;
}
.outlineButton {
  vertical-align: middle;
  padding: 9px 20px 11px 20px;
  line-height: 18px;
  font-size: 14px;
  border: 1px solid #5B5B5B;
  flex-shrink: 0;
  color: #5B5B5B;
  cursor: pointer;
}
.outlineButton:hover {
  background: #008AC9;
  color: #ffffff;
  border-color: #008AC9;
}
.configurator3d__hint {
  padding: 20px 20px 20px 40px;
  background: #f0f0f0;
  margin-bottom: 10px;
  position: relative;
}
.configurator3d__hint:before {
  content: "";
  font-family: icons;
  font-size: 29px;
  line-height: 1;
  color: #008AC9;
  position: absolute;
  left: 10px;
  top: 17px;
}
.configurator3d__hint.hintInfo:before {
  content: "\E015";
}
.configurator3d__hint.hintError:before {
  content: "\E011";
  color: #C91226;
}
.configurator3d__hint.hintQuestion:before {
  content: "\E025";
  color: #C91226;
}
.configurator3d__hint.hintSuccess:before {
  content: "\E00B";
  color: #1EA515;
}
@font-face {
  font-family: "icons";
  font-display: swap;
  src: url('./icons.woff2') format('woff2');
}
.icon-360:before {
  content: "\E001";
}
.icon-3d-360-circle:before {
  content: "\E002";
}
.icon-3d-angle-down:before {
  content: "\E003";
}
.icon-3d-angle-left:before {
  content: "\E004";
}
.icon-3d-angle-right:before {
  content: "\E005";
}
.icon-3d-angle-up:before {
  content: "\E006";
}
.icon-3d-arrow-left:before {
  content: "\E007";
}
.icon-3d-arrow-right:before {
  content: "\E008";
}
.icon-3d-background:before {
  content: "\E009";
}
.icon-3d-center-align:before {
  content: "\E00A";
}
.icon-3d-check:before {
  content: "\E00B";
}
.icon-3d-color:before {
  content: "\E00C";
}
.icon-3d-cross:before {
  content: "\E00D";
}
.icon-3d-cut:before {
  content: "\E00E";
}
.icon-3d-design:before {
  content: "\E00F";
}
.icon-3d-duplicate:before {
  content: "\E010";
}
.icon-3d-exclamation:before {
  content: "\E011";
}
.icon-3d-file:before {
  content: "\E012";
}
.icon-3d-foreground:before {
  content: "\E013";
}
.icon-3d-heart:before {
  content: "\E014";
}
.icon-3d-info:before {
  content: "\E015";
}
.icon-3d-italic:before {
  content: "\E016";
}
.icon-3d-jakoloader:before {
  content: "\E017";
}
.icon-3d-left-align:before {
  content: "\E018";
}
.icon-3d-lock:before {
  content: "\E019";
}
.icon-3d-logo:before {
  content: "\E01A";
}
.icon-3d-long-arrow-left:before {
  content: "\E01B";
}
.icon-3d-long-arrow-right:before {
  content: "\E01C";
}
.icon-3d-menu:before {
  content: "\E01D";
}
.icon-3d-minus:before {
  content: "\E01E";
}
.icon-3d-move:before {
  content: "\E01F";
}
.icon-3d-name:before {
  content: "\E020";
}
.icon-3d-number:before {
  content: "\E021";
}
.icon-3d-pattern:before {
  content: "\E022";
}
.icon-3d-plus:before {
  content: "\E023";
}
.icon-3d-price:before {
  content: "\E024";
}
.icon-3d-question:before {
  content: "\E025";
}
.icon-3d-right-align:before {
  content: "\E026";
}
.icon-3d-rotate:before {
  content: "\E027";
}
.icon-3d-scale:before {
  content: "\E028";
}
.icon-3d-team:before {
  content: "\E029";
}
.icon-3d-text:before {
  content: "\E02A";
}
.icon-3d-trash:before {
  content: "\E02B";
}
.icon-3d-upload:before {
  content: "\E02C";
}
.icon-3d-uppercase:before {
  content: "\E02D";
}
.icon-3d-zoom-in:before {
  content: "\E02E";
}
.icon-3d-zoom-out:before {
  content: "\E02F";
}
.icon-add-user:before {
  content: "\E030";
}
.icon-address:before {
  content: "\E031";
}
.icon-angle-down:before {
  content: "\E032";
}
.icon-angle-left:before {
  content: "\E033";
}
.icon-angle-right:before {
  content: "\E034";
}
.icon-angle-up:before {
  content: "\E035";
}
.icon-arrow-down:before {
  content: "\E036";
}
.icon-arrow-left:before {
  content: "\E037";
}
.icon-arrow-right:before {
  content: "\E038";
}
.icon-arrow-up:before {
  content: "\E039";
}
.icon-arv:before {
  content: "\E03A";
}
.icon-basket-add:before {
  content: "\E03B";
}
.icon-basket:before {
  content: "\E03C";
}
.icon-basketball:before {
  content: "\E03D";
}
.icon-chat:before {
  content: "\E03E";
}
.icon-check-circle:before {
  content: "\E03F";
}
.icon-check:before {
  content: "\E040";
}
.icon-children:before {
  content: "\E041";
}
.icon-clock:before {
  content: "\E042";
}
.icon-close:before {
  content: "\E043";
}
.icon-columns:before {
  content: "\E044";
}
.icon-csv:before {
  content: "\E045";
}
.icon-customize:before {
  content: "\E046";
}
.icon-date:before {
  content: "\E047";
}
.icon-dealerdays:before {
  content: "\E048";
}
.icon-design:before {
  content: "\E049";
}
.icon-discontinued:before {
  content: "\E04A";
}
.icon-document-archive:before {
  content: "\E04B";
}
.icon-double-left:before {
  content: "\E04C";
}
.icon-double-right:before {
  content: "\E04D";
}
.icon-download:before {
  content: "\E04E";
}
.icon-drag-drop:before {
  content: "\E04F";
}
.icon-edit:before {
  content: "\E050";
}
.icon-euro:before {
  content: "\E051";
}
.icon-exchange:before {
  content: "\E052";
}
.icon-exclamation-circle:before {
  content: "\E053";
}
.icon-exclamation:before {
  content: "\E054";
}
.icon-express:before {
  content: "\E055";
}
.icon-eye:before {
  content: "\E056";
}
.icon-facebook:before {
  content: "\E057";
}
.icon-file-doc:before {
  content: "\E058";
}
.icon-file-document:before {
  content: "\E059";
}
.icon-file-image:before {
  content: "\E05A";
}
.icon-file-pdf:before {
  content: "\E05B";
}
.icon-file-powerpoint:before {
  content: "\E05C";
}
.icon-file-video:before {
  content: "\E05D";
}
.icon-file-xls:before {
  content: "\E05E";
}
.icon-filter:before {
  content: "\E05F";
}
.icon-fitness:before {
  content: "\E060";
}
.icon-font:before {
  content: "\E061";
}
.icon-fontcolor:before {
  content: "\E062";
}
.icon-freeGift01:before {
  content: "\E063";
}
.icon-freeGift02:before {
  content: "\E064";
}
.icon-freeGift03:before {
  content: "\E065";
}
.icon-gift:before {
  content: "\E066";
}
.icon-give-and-go:before {
  content: "\E067";
}
.icon-handball:before {
  content: "\E068";
}
.icon-heart-filled:before {
  content: "\E069";
}
.icon-heart:before {
  content: "\E06A";
}
.icon-hide:before {
  content: "\E06B";
}
.icon-home:before {
  content: "\E06C";
}
.icon-home_1:before {
  content: "\E06D";
}
.icon-info-circle:before {
  content: "\E06E";
}
.icon-instagram:before {
  content: "\E06F";
}
.icon-itemcolor:before {
  content: "\E070";
}
.icon-jacket:before {
  content: "\E071";
}
.icon-jako:before {
  content: "\E072";
}
.icon-language:before {
  content: "\E073";
}
.icon-linkedin:before {
  content: "\E074";
}
.icon-list:before {
  content: "\E075";
}
.icon-lock:before {
  content: "\E076";
}
.icon-login:before {
  content: "\E077";
}
.icon-logout:before {
  content: "\E078";
}
.icon-mail:before {
  content: "\E079";
}
.icon-man:before {
  content: "\E07A";
}
.icon-map-pin:before {
  content: "\E07B";
}
.icon-minus:before {
  content: "\E07C";
}
.icon-payment:before {
  content: "\E07D";
}
.icon-phone:before {
  content: "\E07E";
}
.icon-pinterest:before {
  content: "\E07F";
}
.icon-plus-circle:before {
  content: "\E080";
}
.icon-plus:before {
  content: "\E081";
}
.icon-prices:before {
  content: "\E082";
}
.icon-print:before {
  content: "\E083";
}
.icon-question:before {
  content: "\E084";
}
.icon-quickorder:before {
  content: "\E085";
}
.icon-reload:before {
  content: "\E086";
}
.icon-running:before {
  content: "\E087";
}
.icon-save:before {
  content: "\E088";
}
.icon-search:before {
  content: "\E089";
}
.icon-send:before {
  content: "\E08A";
}
.icon-set:before {
  content: "\E08B";
}
.icon-settings:before {
  content: "\E08C";
}
.icon-share:before {
  content: "\E08D";
}
.icon-shipping:before {
  content: "\E08E";
}
.icon-shirt-group:before {
  content: "\E08F";
}
.icon-shirt:before {
  content: "\E090";
}
.icon-shirt2:before {
  content: "\E091";
}
.icon-show:before {
  content: "\E092";
}
.icon-soccer:before {
  content: "\E093";
}
.icon-star-filled:before {
  content: "\E094";
}
.icon-star:before {
  content: "\E095";
}
.icon-store:before {
  content: "\E096";
}
.icon-teamshop:before {
  content: "\E097";
}
.icon-tennis:before {
  content: "\E098";
}
.icon-trash:before {
  content: "\E099";
}
.icon-trikot:before {
  content: "\E09A";
}
.icon-twitter:before {
  content: "\E09B";
}
.icon-unlock:before {
  content: "\E09C";
}
.icon-upload:before {
  content: "\E09D";
}
.icon-user:before {
  content: "\E09E";
}
.icon-users:before {
  content: "\E09F";
}
.icon-versandkostenfrei:before {
  content: "\E0A0";
}
.icon-volleyball:before {
  content: "\E0A1";
}
.icon-whatsapp:before {
  content: "\E0A2";
}
.icon-woman:before {
  content: "\E0A3";
}
.icon-work:before {
  content: "\E0A4";
}
.icon-xing:before {
  content: "\E0A5";
}
.icon-youtube:before {
  content: "\E0A6";
}
.icon-zoom:before {
  content: "\E0A7";
}
/*

<i class="icon icon-360"></i>

<i class="icon icon-3d-360-circle"></i>

<i class="icon icon-3d-angle-down"></i>

<i class="icon icon-3d-angle-left"></i>

<i class="icon icon-3d-angle-right"></i>

<i class="icon icon-3d-angle-up"></i>

<i class="icon icon-3d-arrow-left"></i>

<i class="icon icon-3d-arrow-right"></i>

<i class="icon icon-3d-background"></i>

<i class="icon icon-3d-center-align"></i>

<i class="icon icon-3d-check"></i>

<i class="icon icon-3d-color"></i>

<i class="icon icon-3d-cross"></i>

<i class="icon icon-3d-cut"></i>

<i class="icon icon-3d-design"></i>

<i class="icon icon-3d-duplicate"></i>

<i class="icon icon-3d-exclamation"></i>

<i class="icon icon-3d-file"></i>

<i class="icon icon-3d-foreground"></i>

<i class="icon icon-3d-heart"></i>

<i class="icon icon-3d-info"></i>

<i class="icon icon-3d-italic"></i>

<i class="icon icon-3d-jakoloader"></i>

<i class="icon icon-3d-left-align"></i>

<i class="icon icon-3d-lock"></i>

<i class="icon icon-3d-logo"></i>

<i class="icon icon-3d-long-arrow-left"></i>

<i class="icon icon-3d-long-arrow-right"></i>

<i class="icon icon-3d-menu"></i>

<i class="icon icon-3d-minus"></i>

<i class="icon icon-3d-move"></i>

<i class="icon icon-3d-name"></i>

<i class="icon icon-3d-number"></i>

<i class="icon icon-3d-pattern"></i>

<i class="icon icon-3d-plus"></i>

<i class="icon icon-3d-price"></i>

<i class="icon icon-3d-question"></i>

<i class="icon icon-3d-right-align"></i>

<i class="icon icon-3d-rotate"></i>

<i class="icon icon-3d-scale"></i>

<i class="icon icon-3d-team"></i>

<i class="icon icon-3d-text"></i>

<i class="icon icon-3d-trash"></i>

<i class="icon icon-3d-upload"></i>

<i class="icon icon-3d-uppercase"></i>

<i class="icon icon-3d-zoom-in"></i>

<i class="icon icon-3d-zoom-out"></i>

<i class="icon icon-add-user"></i>

<i class="icon icon-address"></i>

<i class="icon icon-angle-down"></i>

<i class="icon icon-angle-left"></i>

<i class="icon icon-angle-right"></i>

<i class="icon icon-angle-up"></i>

<i class="icon icon-arrow-down"></i>

<i class="icon icon-arrow-left"></i>

<i class="icon icon-arrow-right"></i>

<i class="icon icon-arrow-up"></i>

<i class="icon icon-arv"></i>

<i class="icon icon-basket-add"></i>

<i class="icon icon-basket"></i>

<i class="icon icon-basketball"></i>

<i class="icon icon-chat"></i>

<i class="icon icon-check-circle"></i>

<i class="icon icon-check"></i>

<i class="icon icon-children"></i>

<i class="icon icon-clock"></i>

<i class="icon icon-close"></i>

<i class="icon icon-columns"></i>

<i class="icon icon-csv"></i>

<i class="icon icon-customize"></i>

<i class="icon icon-date"></i>

<i class="icon icon-dealerdays"></i>

<i class="icon icon-design"></i>

<i class="icon icon-discontinued"></i>

<i class="icon icon-document-archive"></i>

<i class="icon icon-double-left"></i>

<i class="icon icon-double-right"></i>

<i class="icon icon-download"></i>

<i class="icon icon-drag-drop"></i>

<i class="icon icon-edit"></i>

<i class="icon icon-euro"></i>

<i class="icon icon-exchange"></i>

<i class="icon icon-exclamation-circle"></i>

<i class="icon icon-exclamation"></i>

<i class="icon icon-express"></i>

<i class="icon icon-eye"></i>

<i class="icon icon-facebook"></i>

<i class="icon icon-file-doc"></i>

<i class="icon icon-file-document"></i>

<i class="icon icon-file-image"></i>

<i class="icon icon-file-pdf"></i>

<i class="icon icon-file-powerpoint"></i>

<i class="icon icon-file-video"></i>

<i class="icon icon-file-xls"></i>

<i class="icon icon-filter"></i>

<i class="icon icon-fitness"></i>

<i class="icon icon-font"></i>

<i class="icon icon-fontcolor"></i>

<i class="icon icon-freeGift01"></i>

<i class="icon icon-freeGift02"></i>

<i class="icon icon-freeGift03"></i>

<i class="icon icon-gift"></i>

<i class="icon icon-give-and-go"></i>

<i class="icon icon-handball"></i>

<i class="icon icon-heart-filled"></i>

<i class="icon icon-heart"></i>

<i class="icon icon-hide"></i>

<i class="icon icon-home"></i>

<i class="icon icon-home_1"></i>

<i class="icon icon-info-circle"></i>

<i class="icon icon-instagram"></i>

<i class="icon icon-itemcolor"></i>

<i class="icon icon-jacket"></i>

<i class="icon icon-jako"></i>

<i class="icon icon-language"></i>

<i class="icon icon-linkedin"></i>

<i class="icon icon-list"></i>

<i class="icon icon-lock"></i>

<i class="icon icon-login"></i>

<i class="icon icon-logout"></i>

<i class="icon icon-mail"></i>

<i class="icon icon-man"></i>

<i class="icon icon-map-pin"></i>

<i class="icon icon-minus"></i>

<i class="icon icon-payment"></i>

<i class="icon icon-phone"></i>

<i class="icon icon-pinterest"></i>

<i class="icon icon-plus-circle"></i>

<i class="icon icon-plus"></i>

<i class="icon icon-prices"></i>

<i class="icon icon-print"></i>

<i class="icon icon-question"></i>

<i class="icon icon-quickorder"></i>

<i class="icon icon-reload"></i>

<i class="icon icon-running"></i>

<i class="icon icon-save"></i>

<i class="icon icon-search"></i>

<i class="icon icon-send"></i>

<i class="icon icon-set"></i>

<i class="icon icon-settings"></i>

<i class="icon icon-share"></i>

<i class="icon icon-shipping"></i>

<i class="icon icon-shirt-group"></i>

<i class="icon icon-shirt"></i>

<i class="icon icon-shirt2"></i>

<i class="icon icon-show"></i>

<i class="icon icon-soccer"></i>

<i class="icon icon-star-filled"></i>

<i class="icon icon-star"></i>

<i class="icon icon-store"></i>

<i class="icon icon-teamshop"></i>

<i class="icon icon-tennis"></i>

<i class="icon icon-trash"></i>

<i class="icon icon-trikot"></i>

<i class="icon icon-twitter"></i>

<i class="icon icon-unlock"></i>

<i class="icon icon-upload"></i>

<i class="icon icon-user"></i>

<i class="icon icon-users"></i>

<i class="icon icon-versandkostenfrei"></i>

<i class="icon icon-volleyball"></i>

<i class="icon icon-whatsapp"></i>

<i class="icon icon-woman"></i>

<i class="icon icon-work"></i>

<i class="icon icon-xing"></i>

<i class="icon icon-youtube"></i>

<i class="icon icon-zoom"></i>

*/
.icon {
  font-family: "icons";
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 0.8em;
}
.icon-3d-360-circle:before {
  content: "\E002";
}
.icon-3d-angle-down:before {
  content: "\E003";
}
.icon-3d-angle-left:before {
  content: "\E004";
}
.icon-3d-angle-right:before {
  content: "\E005";
}
.icon-3d-angle-up:before {
  content: "\E006";
}
.icon-3d-arrow-left:before {
  content: "\E007";
}
.icon-3d-arrow-right:before {
  content: "\E008";
}
.icon-3d-background:before {
  content: "\E009";
}
.icon-3d-center-align:before {
  content: "\E00A";
}
.icon-3d-check:before {
  content: "\E00B";
}
.icon-3d-color:before {
  content: "\E00C";
}
.icon-3d-close:before {
  content: "\E00D";
}
.icon-3d-cut:before {
  content: "\E00E";
}
.icon-3d-design:before {
  content: "\E00F";
}
.icon-3d-duplicate:before {
  content: "\E010";
}
.icon-3d-file:before {
  content: "\E012";
}
.icon-3d-foreground:before {
  content: "\E013";
}
.icon-3d-heart:before {
  content: "\E014";
}
.icon-3d-heart:before {
  content: "\E014";
}
.icon-3d-info:before {
  content: "\E015";
}
.icon-3d-italic:before {
  content: "\E016";
}
.icon-3d-left-align:before {
  content: "\E018";
}
.icon-3d-loading:before {
  content: "\E017";
}
.icon-3d-lock:before {
  content: "\E019";
}
.icon-3d-logo:before {
  content: "\E01A";
}
.icon-3d-long-arrow-left:before {
  content: "\E01B";
}
.icon-3d-long-arrow-right:before {
  content: "\E01C";
}
.icon-3d-menu:before {
  content: "\E01D";
}
.icon-3d-minus:before {
  content: "\E01E";
}
.icon-3d-move:before {
  content: "\E01F";
}
.icon-3d-number:before {
  content: "\E021";
}
.icon-3d-name:before {
  content: "\E020";
}
.icon-3d-pattern:before {
  content: "\E022";
}
.icon-3d-plus:before {
  content: "\E023";
}
.icon-3d-price:before {
  content: "\E024";
}
.icon-3d-question:before {
  content: "\E025";
}
.icon-3d-right-align:before {
  content: "\E026";
}
.icon-3d-rotate:before {
  content: "\E027";
}
.icon-3d-scale:before {
  content: "\E028";
}
.icon-3d-team:before {
  content: "\E029";
}
.icon-3d-text:before {
  content: "\E02A";
}
.icon-3d-trash:before {
  content: "\E02B";
}
.icon-3d-upload:before {
  content: "\E02C";
}
.icon-3d-uppercase:before {
  content: "\E02D";
}
.icon-3d-zoom-in:before {
  content: "\E02E";
}
.icon-3d-zoom-out:before {
  content: "\E02F";
}
.icon-spinner-up:before {
  content: "\E081";
}
.icon-spinner-down:before {
  content: "\E07C";
}
.itemsWrapper--4cols {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.itemsWrapper--4cols .itemTemplate {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 10px 10px 29px 10px;
  position: relative;
}
.itemsWrapper--4cols .itemTemplate__image {
  flex-grow: 1;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}
.itemsWrapper--4cols .itemTemplate__description {
  font-size: 1.2rem;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  width: 100%;
  text-align: center;
  opacity: 0;
  -webkit-transition: 0.3s all ease-in-out;
  -moz-transition: 0.3s all ease-in-out;
  -ms-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  position: absolute;
  bottom: 5px;
  transform: translate(0, 20%);
}
.itemsWrapper--4cols .itemTemplate img {
  aspect-ratio: 1;
  width: 72px;
  -webkit-transition: 0.3s all ease-in-out;
  -moz-transition: 0.3s all ease-in-out;
  -ms-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
}
.itemsWrapper--4cols .itemTemplate.active {
  pointer-events: none;
  background: #f0f0f0;
  border-bottom: 2px solid #008AC9;
}
.itemsWrapper--4cols .itemTemplate.active .itemTemplate__description {
  opacity: 1;
  transform: translate(0);
}
.itemsWrapper--4cols .itemTemplate:hover {
  background: #f0f0f0;
}
.itemsWrapper--4cols .itemTemplate:hover .itemTemplate__description {
  opacity: 1;
  transform: translate(0);
}

.itemsWrapper--3cols {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 20px;
  position: relative;
}
.itemsWrapper--3cols .itemTemplate {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 28px 28px 54px 28px;
  position: relative;
}
.itemsWrapper--3cols .itemTemplate__image {
  flex-grow: 1;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}
.itemsWrapper--3cols .itemTemplate__description {
  font-size: 1.4rem;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  width: 100%;
  text-align: center;
  opacity: 0;
  -webkit-transition: 0.3s all ease-in-out;
  -moz-transition: 0.3s all ease-in-out;
  -ms-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  position: absolute;
  bottom: 20px;
  transform: translate(0, 20%);
}
.itemsWrapper--3cols .itemTemplate img {
  aspect-ratio: 1;
  width: 72px;
  -webkit-transition: 0.3s all ease-in-out;
  -moz-transition: 0.3s all ease-in-out;
  -ms-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
}
.itemsWrapper--3cols .itemTemplate.active {
  pointer-events: none;
  background: #f0f0f0;
  border-bottom: 2px solid #008AC9;
}
.itemsWrapper--3cols .itemTemplate.active .itemTemplate__description {
  opacity: 1;
  transform: translate(0);
}
.itemsWrapper--3cols .itemTemplate:hover {
  background: #f0f0f0;
}
.itemsWrapper--3cols .itemTemplate:hover .itemTemplate__description {
  opacity: 1;
  transform: translate(0);
}
.itemsWrapper--2cols {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px 10px;
}
.itemsWrapper--2cols .itemTemplate {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.itemsWrapper--2cols .itemTemplate__image {
  flex-grow: 1;
  position: relative;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
}
.itemsWrapper--2cols .itemTemplate__image:after {
  content: "\E00B";
  font-family: icons;
  font-size: 32px;
  color: #008AC9;
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  background: #ffffff;
  border-radius: 50%;
  border: 1px solid #008AC9;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
}
.itemsWrapper--2cols .itemTemplate__image:before {
  content: '';
  position: absolute;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: calc(1px/-1);
  left: calc(1px/-1);
  background: linear-gradient(to right, #5B5B5B 0%, #5B5B5B 100%), linear-gradient(to top, #5B5B5B 50%, transparent 50%), linear-gradient(to top, #5B5B5B 50%, transparent 50%), linear-gradient(to right, #5B5B5B 0%, #5B5B5B 100%), linear-gradient(to left, #5B5B5B 0%, #5B5B5B 100%);
  background-size: 100% 1px, 1px 200%, 1px 200%, 0% 1px, 0% 1px;
  background-position: 50% 100%, 0% 0%, 100% 0%, 100% 0%, 0% 0%;
  background-repeat: no-repeat, no-repeat;
  transition: transform 0.3s ease-in-out, background-position 0.3s ease-in-out, background-size 0.3s ease-in-out;
  transform: scaleX(0) rotate(0deg);
  transition-delay: 0.3s, 0.15s, 0s;
}
.itemsWrapper--2cols .itemTemplate__image--ratio4to3 {
  padding: 0;
}
.itemsWrapper--2cols .itemTemplate__image--ratio4to3 img {
  aspect-ratio: 1.33333333 !important;
  object-fit: cover;
  width: 100% !important;
  padding-bottom: 10px;
}
.itemsWrapper--2cols .itemTemplate__image--ratio4to3:hover img {
  aspect-ratio: 1.33333333 !important;
}
.itemsWrapper--2cols .itemTemplate__description {
  font-size: 1.4rem;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  border-top: 2px solid #f0f0f0;
  width: 100%;
  text-align: center;
}
.itemsWrapper--2cols .itemTemplate img {
  aspect-ratio: 1;
  width: 132px;
  -webkit-transition: 0.3s all ease-in-out;
  -moz-transition: 0.3s all ease-in-out;
  -ms-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
}
.itemsWrapper--2cols .itemTemplate.active {
  pointer-events: none;
  color: #008AC9;
}
.itemsWrapper--2cols .itemTemplate.active .itemTemplate__image:after {
  display: flex;
}
.itemsWrapper--2cols .itemTemplate:hover .itemTemplate__image:before {
  background-size: 200% 1px, 1px 400%, 1px 400%, 55% 1px, 55% 1px;
  background-position: 50% 100%, 0% 100%, 100% 100%, 100% 0%, 0% 0%;
  transform: scaleX(1) rotate(0deg);
  transition-delay: 0s, 0.15s, 0.3s;
}
.itemsWrapper--2cols .itemTemplate:hover .itemTemplate__image img {
  transform: scale(0.92);
}
.itemsWrapper--2cols .itemTemplate:hover .itemTemplate__image--ratio4to3 img {
  transform: scale(0.88);
  padding-bottom: 0;
}
.itemsWrapper--2cols .itemTemplate:hover .itemTemplate__description {
  border-top: 1px solid #f0f0f0;
}
.itemsWrapper--1col {
  margin-top: 10px;
}
.itemsWrapper--1col .itemTemplate {
  padding: 10px 20px;
  margin-bottom: 10px;
  border: 1px solid #f0f0f0;
  position: relative;
}
.itemsWrapper--1col .itemTemplate:before {
  content: '';
  position: absolute;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: calc(1px/-1);
  left: calc(1px/-1);
  background: linear-gradient(to right, #5B5B5B 0%, #5B5B5B 100%), linear-gradient(to top, #5B5B5B 50%, transparent 50%), linear-gradient(to top, #5B5B5B 50%, transparent 50%), linear-gradient(to right, #5B5B5B 0%, #5B5B5B 100%), linear-gradient(to left, #5B5B5B 0%, #5B5B5B 100%);
  background-size: 100% 1px, 1px 200%, 1px 200%, 0% 1px, 0% 1px;
  background-position: 50% 100%, 0% 0%, 100% 0%, 100% 0%, 0% 0%;
  background-repeat: no-repeat, no-repeat;
  transition: transform 0.3s ease-in-out, background-position 0.3s ease-in-out, background-size 0.3s ease-in-out;
  transform: scaleX(0) rotate(0deg);
  transition-delay: 0.3s, 0.15s, 0s;
}
.itemsWrapper--1col .itemTemplate:after {
  content: "\E00B";
  font-family: icons;
  font-size: 32px;
  color: #008AC9;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
}
.itemsWrapper--1col .itemTemplate.active {
  border: 1px solid #008AC9;
}
.itemsWrapper--1col .itemTemplate.active:after {
  display: block;
}
.itemsWrapper--1col .itemTemplate.active:hover:before {
  display: none;
}
.itemsWrapper--1col .itemTemplate:hover {
  cursor: pointer;
}
.itemsWrapper--1col .itemTemplate:hover:before {
  background-size: 200% 1px, 1px 400%, 1px 400%, 55% 1px, 55% 1px;
  background-position: 50% 100%, 0% 100%, 100% 100%, 100% 0%, 0% 0%;
  transform: scaleX(1) rotate(0deg);
  transition-delay: 0s, 0.15s, 0.3s;
}
.configurator3D__wrapper * {
  scrollbar-width: auto;
  scrollbar-color: #c3c3c3 #f0f0f0;
}
.configurator3D__wrapper *::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.configurator3D__wrapper *::-webkit-scrollbar-track {
  background: #f0f0f0;
}
.configurator3D__wrapper *::-webkit-scrollbar-thumb {
  background-color: #c3c3c3;
  border-radius: 2px;
  border: 2px none #ffffff;
}
.tabs.borderBottom {
  border-bottom: 2px solid #f0f0f0;
  margin-bottom: 10px;
}
.tabs__list {
  position: relative;
  margin: 0;
  padding: 0px;
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  grid-gap: 10px;
}
@media (min-width: 768px) {
  .tabs__list {
    margin-bottom: 20px;
  }
}
.tabs__list--3cols .tabs__li {
  width: 33.33333333%;
}
.tabs__li {
  position: relative;
  padding: 5px 0 10px;
  flex-grow: 1;
  text-align: center;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  font-weight: 600;
  color: #c3c3c3;
  border-bottom: 1px solid #c3c3c3;
  font-size: 14px;
}
.tabs__li:before {
  transition: width 300ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  left: 0;
  width: 0;
  transform-origin: left center;
  content: "";
  position: absolute;
  bottom: -1px;
  height: 2px;
  background-color: #c3c3c3;
}
.tabs__li.active,
.tabs__li:hover,
.tabs__li:active,
.tabs__li:visited {
  cursor: pointer;
  color: #008AC9;
}
.tabs__li.active:before,
.tabs__li:hover:before,
.tabs__li:active:before,
.tabs__li:visited:before {
  background-color: #008AC9;
  width: 100%;
}
.tabs__content {
  display: none;
}
.tabs__content.active {
  display: block;
}
.tooltip {
  position: relative;
}
.tooltip:hover > label,
.tooltip:focus > label {
  opacity: 1;
  margin: 0;
  transform: translateX(-50%) scale(1);
}
label.tooltipBubble {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scale(0);
  color: #ffffff;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  font-size: 12px;
  background: #008AC9;
  border-radius: 0;
  padding: 5px 10px 8px;
  bottom: calc(100% + 11px);
  bottom: -moz-calc(100% + 11px);
  bottom: -webkit-calc(100% + 11px);
  bottom: -o-calc(100% + 11px);
  line-height: 1em;
  pointer-events: none;
  margin-bottom: -0.5em;
  transition: transform 0.3s ease-in-out;
  opacity: 0;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
label.tooltipBubble:after {
  content: '';
  display: block;
  height: 0;
  border-left: 16px solid transparent;
  border-right: 0 solid transparent;
  border-top: 10px solid #008AC9;
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -13px;
}
@font-face {
  font-family: "franklin-gothic-urw";
  src: url("https://use.typekit.net/af/9cb78a/0000000000000000000118ad/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/9cb78a/0000000000000000000118ad/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/9cb78a/0000000000000000000118ad/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "franklin-gothic-urw";
  src: url("https://use.typekit.net/af/2e6f07/000000000000000000011ce6/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/2e6f07/000000000000000000011ce6/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/2e6f07/000000000000000000011ce6/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "franklin-gothic-urw";
  src: url("https://use.typekit.net/af/b825af/0000000000000000000118b1/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/b825af/0000000000000000000118b1/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/b825af/0000000000000000000118b1/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "franklin-gothic-urw";
  src: url("https://use.typekit.net/af/c7b613/0000000000000000000118b3/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n9&v=3") format("woff2"), url("https://use.typekit.net/af/c7b613/0000000000000000000118b3/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n9&v=3") format("woff"), url("https://use.typekit.net/af/c7b613/0000000000000000000118b3/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n9&v=3") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 900;
}
@font-face {
  font-family: "franklin-gothic-urw-cond";
  src: url("https://use.typekit.net/af/bb00d4/00000000000000003b9b2244/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/bb00d4/00000000000000003b9b2244/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/bb00d4/00000000000000003b9b2244/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}
/*@font-face {
  font-family:"franklin-gothic-urw-cond";
  src:url("https://use.typekit.net/af/a30f3c/00000000000000003b9b2245/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/a30f3c/00000000000000003b9b2245/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/a30f3c/00000000000000003b9b2245/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3") format("opentype");
  font-display:swap;font-style:normal;font-weight:500;
}*/
@font-face {
  font-family: "franklin-gothic-urw-cond";
  src: url("https://use.typekit.net/af/a2031c/0000000000000000000118b9/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/a2031c/0000000000000000000118b9/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/a2031c/0000000000000000000118b9/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}
body {
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  font-family: "franklin-gothic-urw", Helvetica, Arial, sans-serif;
  color: #5B5B5B;
}
button {
  font-family: "franklin-gothic-urw-cond";
}
@media (max-width: 1539px) {
  body {
    font-size: 14px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "franklin-gothic-urw", Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1.4;
  color: #5B5B5B;
  margin-top: 0;
  margin-bottom: 1em;
}
h1,
.h1 {
  font-size: 38px;
  line-height: 1;
  margin-bottom: 40px;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 0.6px;
}
@media (max-width: 767px) {
  h1,
  .h1 {
    font-size: 28px;
  }
}
@media (max-width: 480px) {
  h1,
  .h1 {
    font-size: 24px;
    margin-bottom: 30px;
  }
}
h1 small,
.h1 small {
  color: #5B5B5B;
  font-weight: 700;
}
h1.big,
.h1.big {
  font-size: 50px;
  margin-bottom: 40px;
}
@media (max-width: 1249px) {
  h1.big,
  .h1.big {
    font-size: 38px;
  }
}
@media (max-width: 767px) {
  h1.big,
  .h1.big {
    font-size: 28px;
    margin-bottom: 30px;
  }
}
@media (max-width: 480px) {
  h1.big,
  .h1.big {
    font-size: 24px;
  }
}
h2,
.h2 {
  font-size: 32px;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 0.6px;
}
@media (max-width: 767px) {
  h2,
  .h2 {
    font-size: 24px;
  }
}
@media (max-width: 480px) {
  h2,
  .h2 {
    font-size: 22px;
    margin-bottom: 20px;
  }
}
h3,
.h3 {
  font-size: 26px;
  line-height: 1;
}
@media (max-width: 767px) {
  h3,
  .h3 {
    font-size: 20px;
  }
}
h4,
.h4 {
  font-size: 22px;
  margin-bottom: 10px;
  text-transform: none;
}
@media (max-width: 767px) {
  h4,
  .h4 {
    font-size: 18px;
  }
}
h5,
.h5 {
  font-size: 18px;
  line-height: 1;
}
@media (max-width: 767px) {
  h5,
  .h5 {
    font-size: 15px;
  }
}
h6,
.h6 {
  font-size: 18px;
  line-height: 1;
}
@media (max-width: 767px) {
  h6,
  .h6 {
    font-size: 14px;
  }
}
.h--line {
  border-bottom: 1px solid #BFBFBF;
  padding-bottom: 10px;
}
a,
.link {
  color: #757579;
  text-decoration: underline;
  cursor: pointer;
  text-underline-position: under;
}
a:hover,
.link:hover,
a:focus,
.link:focus,
a:active,
.link:active {
  color: #008AC9;
  outline: 0;
}
*:focus {
  outline: 0;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-nowrap {
  white-space: nowrap;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
ul,
ol {
  margin: 0;
  padding-left: 20px;
}
ul li,
ol li {
  color: #5B5B5B;
  padding: 5px 0;
}
ol.specialOl {
  margin: 0;
  padding: 0;
  counter-reset: specialOl;
}
ol.specialOl li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
  padding-left: 40px;
  counter-increment: specialOl;
}
ol.specialOl li:not(:last-child) {
  margin-bottom: 20px;
}
ol.specialOl li:before {
  content: counter(specialOl);
  border-radius: 50%;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #008AC9;
  position: absolute;
  left: 0;
  top: -6px;
  color: #ffffff;
  width: 30px;
  height: 30px;
  font-size: 20px;
}
ol.specialOl li.active:before {
  content: "\E040";
  font-weight: normal;
  font-family: "icons";
}
ol.specialOl strong:first-child {
  font-family: "franklin-gothic-urw", Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1.4;
  color: #5B5B5B;
  margin-top: 0;
  margin-bottom: 1em;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 10px;
  display: block;
}
@media (max-width: 767px) {
  ol.specialOl strong:first-child {
    font-size: 15px;
  }
}
ul.specialChecklist {
  margin: 0;
  padding: 0;
}
ul.specialChecklist li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
  padding-left: 40px;
}
ul.specialChecklist li:not(:last-child) {
  margin-bottom: 20px;
}
ul.specialChecklist li:before {
  content: "\E040";
  font-family: "icons";
  border-radius: 50%;
  font-weight: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  left: 0;
  font-size: 26px;
  top: -6px;
}
ul.specialChecklist h3,
ul.specialChecklist .h3 {
  margin-bottom: 10px;
}
ul.specialChecklist strong:first-child {
  font-family: "franklin-gothic-urw", Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1.4;
  color: #5B5B5B;
  margin-top: 0;
  margin-bottom: 1em;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 10px;
  display: block;
}
@media (max-width: 767px) {
  ul.specialChecklist strong:first-child {
    font-size: 15px;
  }
}
ul.specialChecklist.specialChecklist--inline {
  display: flex;
  flex-wrap: wrap;
}
ul.specialChecklist.specialChecklist--inline li {
  padding-left: 25px;
  margin-right: 15px;
}
ul.specialChecklist.specialChecklist--inline li:not(:last-child) {
  margin-bottom: 10px;
}
ul.specialChecklist.specialChecklist--inline li:before {
  font-size: 20px;
  top: -3px;
}
@media (max-width: 767px) {
  ul.specialChecklist.specialChecklist--inline {
    flex-direction: column;
    align-items: flex-start;
  }
}
ul.specialChecklist.specialChecklist--small li {
  padding-left: 0;
}
ul.specialChecklist.specialChecklist--small li:not(:last-child) {
  margin-bottom: 20px;
}
ul.specialChecklist.specialChecklist--small li:before {
  content: "\E040";
  font-family: "icons";
  font-weight: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  color: #008AC9;
  left: 0;
  font-size: 18px;
  top: -2px;
}
ul.specialChecklist.specialChecklist--small.col-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}
@media (max-width: 480px) {
  ul.specialChecklist.specialChecklist--small.col-2 {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
}
ul.checklistArrows {
  margin: 0;
  padding: 0;
}
ul.checklistArrows li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
  padding-left: 25px;
}
ul.checklistArrows li:not(:last-child) {
  margin-bottom: 20px;
}
ul.checklistArrows li:before {
  content: "\E038";
  font-family: "icons";
  border-radius: 50%;
  font-weight: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  color: #008AC9;
  left: 0;
  font-size: 18px;
  top: -2px;
}
ul.checklistArrows h3,
ul.checklistArrows .h3 {
  margin-bottom: 10px;
}
ul.checklistArrows strong:first-child {
  font-family: "franklin-gothic-urw", Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1.4;
  color: #5B5B5B;
  margin-top: 0;
  margin-bottom: 1em;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 10px;
  display: block;
}
@media (max-width: 767px) {
  ul.checklistArrows strong:first-child {
    font-size: 15px;
  }
}
address {
  margin-bottom: 22px;
  font-style: italic;
  line-height: 1.4;
}
hr {
  border-top: 1px solid #BFBFBF;
  margin-top: 40px;
  margin-bottom: 40px;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
label {
  font-size: 15px;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
}
.linkWithIcon {
  display: inline-block;
  margin-right: 20px;
  text-decoration: none;
  position: relative;
  padding-left: 25px;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
  color: #5B5B5B;
  cursor: pointer;
}
.linkWithIcon .icon {
  position: absolute;
  left: 0;
  top: 50%;
  line-height: 20px;
  margin-top: -10px;
  font-size: 1.2em;
}
.linkWithIcon:hover,
.linkWithIcon:focus,
.linkWithIcon:active {
  text-decoration: none;
  color: #008AC9;
}
.font-size-medium {
  font-size: 18px;
  line-height: 1.45;
}
@media (max-width: 480px) {
  .font-size-medium {
    font-size: 16px;
  }
}
.font-size-26 {
  font-size: 26px;
  line-height: 1.39;
}
@media (max-width: 480px) {
  .font-size-26 {
    font-size: 18px;
  }
}
.font-size-2x {
  font-size: 1.5em;
}
.font-size-3x {
  font-size: 2.5em;
}
.prefixHeadline {
  text-transform: uppercase;
  color: #008AC9;
  font-weight: 700;
  display: block;
  font-size: clamp(14px, 0.6em, 20px);
}
.bg--primary .prefixHeadline {
  color: #ffffff;
}
.strokeText {
  font-weight: 900;
  font-style: italic;
  text-transform: uppercase;
  -webkit-text-stroke: 2px #5B5B5B;
  text-shadow: 0 -0.7em #5B5B5B;
  font-size: 80px;
  line-height: 1;
  color: transparent;
}
.configurator3D__wrapper {
  font-size: 1.6rem;
}
.configurator3D__wrapper h1,
.configurator3D__wrapper .h1,
.configurator3D__wrapper h2,
.configurator3D__wrapper .h2,
.configurator3D__wrapper h3,
.configurator3D__wrapper .h3,
.configurator3D__wrapper h4,
.configurator3D__wrapper .h4 {
  font-size: 2rem;
  text-transform: none;
  color: #141414;
  margin: 0;
  margin-bottom: 5px;
  font-weight: 600;
  letter-spacing: 0.4px;
}
.configurator3D__wrapper .textMedium {
  font-size: 1.5rem;
}
.configurator3D__wrapper .textSmall {
  font-size: 1.4rem;
}
#dropArea {
  border: 2px dashed #f0f0f0;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  cursor: pointer;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
}
#dropArea form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#dropArea:hover {
  background: #f0f0f0;
}
#dropArea .icon {
  font-size: 32px;
  margin-bottom: 10px;
}
#dropArea a,
#dropArea .link {
  color: #008AC9;
}
#dropArea.highlight {
  border-color: #008AC9;
}
#dropArea #progressBar {
  margin-top: 20px;
  width: 100%;
}
#dropArea progress[value] {
  /* Reset the default appearance */
  -webkit-appearance: none;
  appearance: none;
  height: 20px;
}
#dropArea progress[value]::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}
#dropArea progress[value]::-webkit-progress-value {
  background-image: -webkit-linear-gradient(left, #00adfc, #008AC9);
  border-radius: 2px;
  background-size: 100%;
}
#previewGallery {
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
}
#previewGallery .icon {
  font-size: 40px;
  width: 40px;
  height: 40px;
  line-height: 1;
  position: absolute;
  right: 0;
  color: #141414;
  cursor: pointer;
}
#previewGallery .icon:hover {
  background: #f0f0f0;
}
#previewGallery img {
  width: 175px;
  height: 175px;
  background: #f0f0f0;
  object-fit: contain;
  padding: 10px;
}
#fileElem {
  display: none;
}
.dcCookieBar {
  position: fixed;
  left: 0;
  bottom: 0;
  line-height: 1.3;
  width: 100%;
  min-width: 320px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 600;
  padding: 15px;
  padding-left: 90px;
  background-color: #ffffff;
  font-size: 14px;
  color: #5B5B5B;
  box-sizing: border-box;
}
.dcCookieBar a {
  color: #008AC9;
  text-decoration: underline;
}
.dcCookieBar a:hover {
  color: #006796;
}
.dcCookieBar__button {
  width: 100%;
  line-height: 1.6;
  padding: 5px 20px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  min-width: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #5B5B5B;
  border: 1px solid transparent;
  background-color: #ffffff;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}
.dcCookieBar__button:hover {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  color: #5B5B5B;
}
.dcCookieBar__button:active,
.dcCookieBar__button:focus {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #5B5B5B;
}
@media (max-width: 1024px) {
  .dcCookieBar__button {
    font-size: 14px;
  }
}
.dcCookieBar__buttonaction {
  border-color: #008AC9;
  color: #ffffff;
  background-color: #008AC9;
}
.dcCookieBar__buttonaction:hover {
  background-color: #0078b0;
  border-color: #0078b0;
  color: #ffffff;
}
.dcCookieBar__buttonaction:active,
.dcCookieBar__buttonaction:focus {
  background-color: #006796;
  border-color: #006796;
  color: #ffffff;
}
.dcCookieBar__buttons {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
}
.dcCookieBar__buttons:before,
.dcCookieBar__buttons:after {
  display: none;
}
.dcCookieBar__buttons .dcCookieBar__button {
  flex-grow: 1;
  margin-left: 7.5px;
  margin-right: 7.5px;
  margin-bottom: 15px;
  box-sizing: border-box;
}
@media (max-width: 767px) {
  .dcCookieBar__buttons .dcCookieBar__button {
    margin-bottom: 7.5px;
  }
  .dcCookieBar__buttons .dcCookieBar__buttonaction {
    order: -1;
    width: 100%;
  }
}
.dcCookieBar__main {
  text-align: center;
}
.dcCookieBar__text {
  padding-left: 7.5px;
  padding-right: 7.5px;
  padding-bottom: 15px;
  text-align: left;
}
.dcCookieBar__links {
  text-align: right;
  font-size: 12px;
}
.dcCookieBar__links a {
  display: inline-block;
  vertical-align: middle;
  margin-left: 7.5px;
  margin-right: 7.5px;
}
.dcCookieBar__center {
  text-align: center;
}
.dcCookieBar__center a {
  display: inline-block;
  vertical-align: middle;
  margin-left: 7.5px;
  margin-right: 7.5px;
}
.dcCookieModal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.3);
  color: #5B5B5B;
  display: none;
  padding-left: 15px;
  padding-right: 15px;
  z-index: 601;
  font-size: 14px;
}
.dcCookieModal__dialog {
  background-color: #ffffff;
  padding: 30px;
  width: 550px;
  max-width: 100%;
  margin: 15px auto;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
@media (max-width: 767px) {
  .dcCookieModal__dialog {
    padding: 15px;
  }
}
@media (min-width: 441px) {
  .dcCookieModal__dialog {
    margin-top: 150px;
  }
}
.dcCookieModal a {
  color: #008AC9;
  text-decoration: underline;
  cursor: pointer;
}
.dcCookieModal a:hover {
  color: #006796;
}
.dcCookieModal .dcCookieBar__button {
  color: #5B5B5B;
  background-color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}
.dcCookieModal .dcCookieBar__button:hover {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  color: #5B5B5B;
}
.dcCookieModal .dcCookieBar__button:active,
.dcCookieModal .dcCookieBar__button:focus {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #5B5B5B;
}
.dcCookieModal .dcCookieBar__buttonaction {
  border-color: #008AC9;
  color: #ffffff;
  background-color: #008AC9;
  cursor: pointer;
}
.dcCookieModal .dcCookieBar__buttonaction:hover {
  background-color: #0078b0;
  border-color: #0078b0;
  color: #ffffff;
}
.dcCookieModal .dcCookieBar__buttonaction:active,
.dcCookieModal .dcCookieBar__buttonaction:focus {
  background-color: #006796;
  border-color: #006796;
  color: #ffffff;
}
.dcCookieModal .dcCookieBar__links {
  margin-top: 15px;
  text-align: left;
}
.dcCookieModal .dcCookieBar__links a {
  margin-left: 0;
  margin-right: 15px;
}
.dcCookieModal.open {
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
}
.dcCookieModalBody__headline {
  font-size: 20px;
  margin-bottom: 15px;
}
.dcCookieModalBody__info {
  margin-bottom: 15px;
}
.dcCookieBarSwitch {
  width: 50px;
  position: relative;
  height: 26px;
  display: inline-block;
  cursor: pointer;
  border-radius: 13px;
  margin: 0;
  background-color: #f0f0f0;
}
.dcCookieBarSwitch span {
  font-style: normal;
  position: absolute;
  left: 3px;
  width: 20px;
  height: 20px;
  background-color: #5B5B5B;
  top: 50%;
  margin-top: -10px;
  display: block;
  pointer-events: none;
  margin-bottom: 0;
  border-radius: 50%;
  transition: 0.1s ease-in-out;
}
.dcCookieBarSwitch input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  margin: 0;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
}
.dcCookieBarSwitch input:checked + span {
  left: 100%;
  margin-left: -23px;
  background-color: #008AC9;
}
.dcCookieBarSwitch__label {
  color: #5B5B5B;
  margin-bottom: 0;
  line-height: 26px;
  margin-right: 10px;
}
.dcCookieBarBox {
  padding: 15px;
  border: 2px solid #f0f0f0;
  margin-top: 15px;
}
.dcCookieBarBox__head {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 15px;
}
.dcCookieBarBox__head:before,
.dcCookieBarBox__head:after {
  display: none;
}
.dcCookieBarBox__title {
  margin-right: 30px;
  font-size: 16px;
}
.dcCookieBarBox__info {
  margin-bottom: 15px;
}
.dcCookieBarMoreBox {
  margin-top: 15px;
}
.dcCookieBarMoreBox.open {
  display: block;
}
.dcCookieBarTable {
  margin-top: 15px;
}
.dcCookieBarTable__row {
  padding: 8px 0;
  border-bottom: 1px solid #f0f0f0;
}
.dcCookieBarTable__row:last-child {
  border-bottom: 0;
}
.dcCookieBarTable__cell--description {
  font-weight: bold;
  font-size: 1.2em;
}
.dcCookieBarTable__cell--switch {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.dcCookieBarTable__cell--switch:before,
.dcCookieBarTable__cell--switch:after {
  display: none;
}
@media (min-width: 768px) {
  .dcCookieBarTable__row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .dcCookieBarTable__row:before,
  .dcCookieBarTable__row:after {
    display: none;
  }
  .dcCookieBarTable__cell {
    flex-grow: 1;
    text-align: left;
  }
  .dcCookieBarTable__cell:first-child {
    padding-right: 10px;
    flex-shrink: 0;
    flex-grow: 0;
    width: 200px;
  }
  .dcCookieBarTable__cell:first-child.dcCookieBarTable__cell--description {
    width: 50%;
  }
  .dcCookieBarTable__cell--switch {
    justify-content: flex-end;
  }
}
@media (max-width: 767px) {
  .dcCookieBarTable__cell:first-child {
    margin-bottom: 4px;
  }
  .dcCookieBarTable__cell:first-child:after {
    content: ":";
  }
}
.dcCookieBarRecurringTable__row {
  padding: 8px 0;
  border-bottom: 1px solid #f0f0f0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.dcCookieBarRecurringTable__row:before,
.dcCookieBarRecurringTable__row:after {
  display: none;
}
.dcCookieBarRecurringTable__row:last-child {
  border-bottom: 0;
}
.dcCookieBarRecurringTable__row--parent .dcCookieBarRecurringTable__label {
  position: relative;
  padding-left: 20px;
}
.dcCookieBarRecurringTable__row--parent .dcCookieBarRecurringTable__label:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 10px;
  border-color: transparent transparent transparent #5B5B5B;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -6px;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}
.dcCookieBarRecurringTable__row--parent .dcCookieBarRecurringTable__label.active:before {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  border-color: transparent transparent transparent #008AC9;
}
.dcCookieBarRecurringTable__row--child {
  padding-left: 20px;
}
.dcCookieBarRecurringTable__label {
  font-weight: bold;
}
.dcCookieBarHistoryTable {
  font-size: 11px;
}
.dcCookieBarHistoryTable__row {
  padding: 8px 0;
  border-bottom: 1px solid #f0f0f0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.dcCookieBarHistoryTable__row:before,
.dcCookieBarHistoryTable__row:after {
  display: none;
}
.dcCookieBarHistoryTable__row:last-child {
  border-bottom: 0;
}
.dcCookieBarHistoryTable__label {
  flex-grow: 1;
  margin-right: 8px;
}
.dcCookieBarHistoryTable__badge {
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 8px;
  margin-top: 1px;
}
.dcCookieBarHistoryTable__badge.active {
  background-color: green;
}
.dcCookieBarHistoryTable__active {
  margin-left: 8px;
  flex-shrink: 0;
  width: 60px;
  text-align: right;
}
.dcCookieBarRecurring {
  z-index: 601;
  position: fixed;
  left: 20px;
  bottom: 20px;
  box-sizing: border-box;
}
.dcCookieBarRecurringBox {
  position: fixed;
  z-index: 601;
  left: 20px;
  bottom: 80px;
  background-color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  width: 400px;
  font-size: 14px;
}
.dcCookieBarRecurringBox__close {
  height: 14px;
  width: 14px;
  cursor: pointer;
}
.dcCookieBarRecurringBox__close svg path {
  fill: #000000;
}
.dcCookieBarRecurringBox__close:hover svg path {
  fill: #008AC9;
}
.dcCookieBarRecurringBox__head {
  padding: 15px;
  border-bottom: 1px solid #f0f0f0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.dcCookieBarRecurringBox__head:before,
.dcCookieBarRecurringBox__head:after {
  display: none;
}
.dcCookieBarRecurringBox__title {
  margin-right: 15px;
}
.dcCookieBarRecurringBox__footer .dcCookieBar__button {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
@media (max-width: 440px) {
  .dcCookieBarRecurringBox {
    max-width: 100%;
    left: 0;
    bottom: 0;
    max-height: var(--vh);
    overflow: hidden;
    overflow-y: auto;
  }
}
.dcCookieBarRecurringButton {
  width: 46px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  background-color: #008AC9;
  color: #ffffff;
  font-size: 20px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.dcCookieBarRecurringButton:hover,
.dcCookieBarRecurringButton:focus {
  background-color: #0078b0;
}
.dcCookieBarRecurringButton svg path {
  fill: #000000;
}
.dcCookieBarRecurringButton .closelabel {
  display: none;
  position: relative;
  top: 3px;
}
.dcCookieBarRecurringButton .openlabel {
  position: relative;
  top: 6px;
}
.dcCookieBarRecurringButton.open .openlabel {
  display: none;
}
.dcCookieBarRecurringButton.open .closelabel {
  display: block;
}
.dcCookieBarTab {
  text-align: center;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
}
.dcCookieBarTab:not(.active) {
  background-color: #f0f0f0;
}
.dcCookieBarTab:hover {
  background-color: #f2f2f2;
}
.dcCookieBarTabContent {
  padding: 15px;
  display: none;
  height: 240px;
  max-height: 50vh;
  overflow: hidden;
  overflow-y: auto;
}
.dcCookieBarTabContent.active {
  display: block;
}
.dcCookieBarTabs__header {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.dcCookieBarTabs__header:before,
.dcCookieBarTabs__header:after {
  display: none;
}
.dcCookieBarTabs__header .dcCookieBarTab {
  flex-grow: 1;
  width: 50%;
}
.dcCookieExternal {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  justify-content: center;
}
.dcCookieExternal:before,
.dcCookieExternal:after {
  display: none;
}
.dcCookieExternal__info {
  padding: 15px;
  border: 1px solid transparent;
  background-color: #ffffff;
  text-align: center;
}
.dcCookieExternal__button {
  line-height: 1.6;
  padding: 5px 20px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  min-width: 40px;
  width: auto;
  font-size: 16px;
  font-weight: bold;
  color: #5B5B5B;
  border: 1px solid transparent;
  background-color: #ffffff;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}
.dcCookieExternal__button:hover {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  color: #5B5B5B;
}
.dcCookieExternal__button:active,
.dcCookieExternal__button:focus {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #5B5B5B;
}
.hidden {
  display: none !important;
}

.color-picker-simplebar .simplebar-track.simplebar-horizontal {
  display: none;
}

@media (min-width: 768px) {
  .colorWrapper--responsive {
    grid-template-columns: repeat(7, 1fr);
  }

  .configurator__sidebarSteps .custom-scrollbars__container {
    width: 100%;
  }
  .configurator__sidebarSteps .custom-scrollbars__container .custom-scrollbars__content {
    width: 100%;
  }
  .configurator__sidebarSteps .custom-scrollbars__container .custom-scrollbars__content .sidebarStep__item {
    width: auto;
    flex-grow: 1;
  }
}

@media (min-width: 1024px) {
  .configurator__mainArea {
    top: 0;
  }
  .tabs__li {
    font-size: 16px;
  }
  .configurator__sidebarSteps {
    border-right: 2px solid #f0f0f0;
    border-top: none;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .configurator__sidebarSteps .custom-scrollbars__content {
    display: block;
    overflow-y: auto;
  }
  .configurator__sidebarSteps .sidebarStep__item {
    width: 90px;
    height: 75px;
    flex-shrink: initial;
  }
  .configurator__sidebarSteps .sidebarStep__icon {
    font-size: 4rem;
  }

  .custom-scrollbars__container {
    grid-area: sidebarContent;
  }

  .configurator__sidebarContent {
    grid-area: initial !important;
  }

  .configurator__container {
    grid-template-areas: "sidebarSteps sidebarContent main" "bottom bottom bottom";
    grid-template-columns: 100px minmax(380px, 456px) 14fr;
    grid-template-rows: calc(var(--vh) - 110px) 110px;
  }

  .configurator__mainArea .mainArea__background {
    background: transparent linear-gradient(0deg, #F8F8F8 0%, #BEBEBE 100%) 0% 0% no-repeat padding-box;
  }

  .configurator__bottomArea {
    border-top: 2px solid #f0f0f0;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px 10px 0;
  }

  .bottomArea--left {
    width: 100%;
    height: auto;
  }

  .bottomArea--right {
    width: 100%;
    height: auto;
  }

  .configurator__bottomArea .bottomArea--left .bottomBar__item.saveItem {
    width: auto;
    height: auto;
    padding: 20px;
    background-color: transparent;
  }

  .configurator__bottomArea .bottomArea--right .button_primary {
    width: auto;
    height: auto;
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .bottomBar__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: 110px;
    color: #141414;
  }
  .bottomBar__item .icon {
    font-size: 40px;
    line-height: 1;
    margin-bottom: 10px;
    margin-right: 0;
    margin-left: 0;
  }

  .controlBar {
    padding: 0;
    bottom: 83px;
    padding: 0;
  }
  .controlBar .icon {
    display: block;
    background-color: #FBFBFB;
  }

  .controlsCircle {
    width: 600px;
    height: 600px;
    transform: perspective(600px) rotateX(75deg);
    margin-bottom: -45px;
  }

  .controlBar .control-front-btn:focus {
    background-color: #008AC9;
    border-color: #008AC9;
  }

  .configurator__sidebarSteps .custom-scrollbars__container {
    width: initial;
  }
  .configurator__sidebarSteps .custom-scrollbars__container .custom-scrollbars__content {
    width: initial;
  }
  .configurator__sidebarSteps .custom-scrollbars__container .custom-scrollbars__content .sidebarStep__item {
    width: 70px;
    flex-grow: initial;
  }
  .configurator__sidebarSteps .custom-scrollbars__container.hide .custom-scrollbars__content .sidebarStep__item {
    width: 86px;
  }
}

html {
  font-size: 10px;
  -webkit-text-size-adjust: none;
	touch-action: manipulation;
  font-family: "franklin-gothic-urw-cond", Helvetica, Arial, sans-serif;
}

.custom-scrollbars__container {
  display: grid;
  height: 100%;
  grid-template: auto / 1fr 20px;
  overflow: hidden;
  position: relative;
}

.custom-scrollbars__container.hide {
  grid-template: auto / 1fr 0;
}

.custom-scrollbars__content {
  -ms-overflow-style: none;
  overflow: auto;
  scrollbar-width: none;
}

.custom-scrollbars__content::-webkit-scrollbar {
  display: none;
}

.custom-scrollbars__scrollbar {
  display: grid;
  gap: 1rem;
  grid-auto-flow: row;
  grid-template: auto 1fr auto / 1fr;
  place-items: center;
}

.custom-scrollbars__track-and-thumb {
  display: block;
  height: 100%;
  position: relative;
  width: 12px;
}

.custom-scrollbars__track {
  bottom: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 12px;
}

.custom-scrollbars__thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 12px;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-SemiBold.ttf');
  font-weight: 600;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Arial Black';
  src: url('../assets/jako/Arial.woff2');
}
@font-face {
  font-family: 'Bebas Neue';
  src: url('../assets/jako/BebasNeue.woff2');
}
@font-face {
  font-family: 'CG Omega';
  src: url('../assets/jako/CGOmega.woff2');
}
@font-face {
  font-family: 'Oswald Stencil';
  src: url('../assets/jako/OswaldStencil.woff2');
}
@font-face {
  font-family: 'Swiss921 BT';
  src: url('../assets/jako/Swiss921BT.woff2');
}
@font-face {
  font-family: 'Akhand Black';
  src: url('../assets/jako/AkhandBlack.otf');
}
@font-face {
  font-family: 'American Captain';
  src: url('../assets/jako/AmericanCaptain.ttf');
}
@font-face {
  font-family: 'Carbon Black';
  src: url('../assets/jako/CarbonBlack.ttf');
}
@font-face {
  font-family: 'CruzAzul';
  src: url('../assets/jako/CruzAzul.ttf');
}
@font-face {
  font-family: 'Komikazoom';
  src: url('../assets/jako/Komikazoom.ttf');
}
@font-face {
  font-family: 'Norwester';
  src: url('../assets/jako/Norwester.otf');
}
@font-face {
  font-family: 'TCCB';
  src: url('../assets/jako/TCCB.ttf');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-confirm-alert {
  width: 90%;
  padding: 16px;
  background-color: #ffffff;
}
.react-confirm-alert-overlay {
  backdrop-filter: blur(15px);
  background: #000000a3 !important;
}
.react-confirm-alert #jako-configurator-3d-wc .outlineButton {
  border-color: #008AC9;
  color: #008AC9;
  font-weight: 500;
}
.react-confirm-alert #jako-configurator-3d-wc .outlineButton:hover {
  color: #ffffff;
}

@media (min-width: 1024px) {
  .react-confirm-alert {
    width: auto;
  }
  .react-confirm-alert-overlay {
    backdrop-filter: none;
    background: rgba(255, 255, 255, 0.9) !important;
  }
}